import { useEffect, useRef, useState } from "react";
import "../../Assets/scss/components/domain-dropdown.scss";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { addDefaultFaviconImgSrc } from "../../Helpers/addDefaultImgSrc";
import { DomainDetailsService } from "../../Services/DomainService/DomainDetails";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DashboardDomainDropdown = () => {
    // Redux Data
    const currentWebsite = useSelector((store: StoreProptypes) => store.currentWebsite);
    const websiteList = useSelector((store: StoreProptypes) => store.websiteList);
    const accessToken = useSelector((store: StoreProptypes) => store.accessToken);

    // Function
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // States
    const [showDomainButtonFlag, setShowDomainButtonFlag] = useState<boolean>(false);
    const [domainButtonActiveFlag, setDomainButtonActiveFlag] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredWebsites, setFilteredWebsites] = useState(websiteList);

    const dropdownRef = useRef<HTMLDivElement | null>(null);


    // Effect to update filtered list
    useEffect(() => {
        if (websiteList.length > 2) {
            setFilteredWebsites(
                websiteList.filter((website) =>
                    website.domain.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        } else {
            setFilteredWebsites(websiteList);
        }
    }, [searchTerm, websiteList]);

    // Dropdown Manage
    useEffect(() => {
        if (websiteList && websiteList.length === 1) {
            setShowDomainButtonFlag(true);
        } else {
            setShowDomainButtonFlag(false);
        }
    }, [websiteList]);

    // Function to show dropdown
    const handleDomainDropdownShow = () => {
        setDomainButtonActiveFlag(true);
    };

    // Function to hide dropdown
    const handleDomainDropdownHide = () => {
        setDomainButtonActiveFlag(false);
        setSearchTerm(""); // Reset search when closing
    };

    // Function to set current website
    const handleSetCurrentWebsite = (websiteID: number) => {
        if (accessToken && websiteID) {
            DomainDetailsService(accessToken, websiteID, dispatch);
            handleDomainDropdownHide();
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                handleDomainDropdownHide();
            }
        }

        if (domainButtonActiveFlag) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [domainButtonActiveFlag]);

    return (
        <div className="aioa_dashboard-domain-dropdown-wrapper" ref={dropdownRef}>
            {domainButtonActiveFlag ? (
                <>
                    {currentWebsite && (
                        <div
                            className={`aioa_dashboard-domain-dropdown-btn ${showDomainButtonFlag ? `single-site` : ``}`}
                            role="button"
                            tabIndex={0}
                            onClick={handleDomainDropdownHide}
                            onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleDomainDropdownHide()}
                            aria-haspopup="true"
                            aria-pressed="true"
                        >
                            {currentWebsite.fav_icon ? (
                                <div className="aioa_dashboard-domain-dropdown-favicon">
                                    <img onError={addDefaultFaviconImgSrc} src={currentWebsite.fav_icon} alt="" />
                                </div>
                            ) : (
                                <div className="aioa_dashboard-domain-dropdown-favicon no-icon">
                                    {currentWebsite.title ? currentWebsite.title.charAt(0) : currentWebsite.domain.charAt(0)}
                                </div>
                            )}
                            <div className="aioa_dashboard-domain-dropdown-text">{currentWebsite.domain}</div>
                        </div>
                    )}

                    <div className="aioa_dashboard-domain-dropdown-list-wrapper">
                        <div className="aioa_dashboard-domain-dropdown-list">
                            {/* Search Bar (Only if more than 2 domains exist) */}
                            {websiteList.length > 2 && (
                                <div className="aioa_dashboard-domain-dropdown-search">

                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder={t('Search domain...')}
                                        aria-label={t('Search domain...')}
                                    />
                                    {searchTerm && (
                                        <button className="aioa_dashboard-domain-dropdown-clear" onClick={() => setSearchTerm("")}>
                                            <span className="visually-hidden">{t("Clear Search")}</span>
                                            <span aria-hidden="true" role="presentation">&#10005;</span>
                                        </button>
                                    )}
                                </div>
                            )}
                            <br />
                            <div aria-live="polite">
                            {filteredWebsites.length > 0 ? (
                                filteredWebsites.map((websiteItem) => (
                                    <div className="aioa_dashboard-domain-dropdown-btn-box" key={websiteItem.id}>
                                        <div
                                            className={`aioa_dashboard-domain-dropdown-btn ${websiteItem.id === currentWebsite?.id ? `active` : ``}`}
                                            role="button"
                                            tabIndex={websiteItem.id === currentWebsite?.id ? -1 : 0}
                                            onClick={() => handleSetCurrentWebsite(websiteItem.id)}
                                            onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleSetCurrentWebsite(websiteItem.id)}
                                        >
                                            {websiteItem.fav_icon ? (
                                                <div className="aioa_dashboard-domain-dropdown-favicon">
                                                    <img onError={addDefaultFaviconImgSrc} src={websiteItem.fav_icon} alt="" />
                                                </div>
                                            ) : (
                                                <div className="aioa_dashboard-domain-dropdown-favicon no-icon">
                                                    {websiteItem.title ? websiteItem.title.charAt(0) : websiteItem.domain.charAt(0)}
                                                </div>
                                            )}
                                            <div className="aioa_dashboard-domain-dropdown-text">{websiteItem.domain}</div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="aioa_dashboard-no-results">{t('No matching domains found')}</p>
                            )}
                            </div>
                        </div>

                        <div className="aioa_dashboard-view-all-domain">
                            <Link to="/front/websites">{t('view all website button label')}</Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {currentWebsite && (
                        <div
                            className={`aioa_dashboard-domain-dropdown-btn ${showDomainButtonFlag ? `single-site` : ``}`}
                            role="button"
                            tabIndex={showDomainButtonFlag ? -1 : 0}
                            onClick={handleDomainDropdownShow}
                            onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleDomainDropdownShow()}
                            aria-haspopup="true"
                            aria-pressed="false"
                        >
                            {currentWebsite.fav_icon ? (
                                <div className="aioa_dashboard-domain-dropdown-favicon">
                                    <img onError={addDefaultFaviconImgSrc} src={currentWebsite.fav_icon} alt="" />
                                </div>
                            ) : (
                                <div className="aioa_dashboard-domain-dropdown-favicon no-icon">
                                    {currentWebsite.title ? currentWebsite.title.charAt(0) : currentWebsite.domain.charAt(0)}
                                </div>
                            )}
                            <div className="aioa_dashboard-domain-dropdown-text">{currentWebsite.domain}</div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default DashboardDomainDropdown;
