import { useSelector } from "react-redux";
import { StoreProptypes } from "../PropTypes/StoreProptypes";

interface PropTypes {
    price: number, // price
    isDiscountPr?: boolean, // flag to apply discount
    discountPr?: number // discount percentage
}

// component to render price before discount
const StrikePrice = (props: PropTypes) => {
    const price = props.price;
    const isDiscountPr = props.isDiscountPr;
    const discountPr = props.discountPr;
    
    let strikePrice: number = price;

    // Redux Data
    const currentUserDiscount = useSelector((store: StoreProptypes) => store.currentUserDiscount);

    // check if discount flag is available and discount percentage is more than 0
    if (isDiscountPr && discountPr && discountPr > 0) {
        strikePrice = price / 100 * (100 - discountPr);
    } 
    // check if user based discount available
    else if (currentUserDiscount) {
        // check if discount type is 2
        if (currentUserDiscount.discount_type === 2) {
            strikePrice = price / 100 * (100 - currentUserDiscount.discount_value);
        }
    }

    return <>{strikePrice.toFixed(2)}</>;
}

export default StrikePrice;