import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import { websitePlanPropType } from "../../PropTypes/WebsitePlanPropType";
import encodeBase64 from "../../Helpers/encodeBase64";
import { saveNormalDomainService } from "../../Services/DomainService/AddNormalDomain";
import StrikePrice from "../../Helpers/strikePrice";
import PaymentButtonBlock from ".";
import parseISODate from "../../Helpers/parseISODate";
import { WebsiteDetailsPropTypes, currentPackageOnlyPropType, websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import { saveNormalWebsiteApi } from "../../Api/DomainApi/SaveNormalDomainApi";

interface PropTypes {
    activePlanType: number, // 1-widget plan, 2- multi domain, 3 - Pre purchase
    activePlanInterval: string | null, // plan interval
    activePlanPrice: number, // active plan price
    activePlanDomain?: WebsiteDetailsPropTypes | websiteListPropType | null, // active plan domain
    forAddNewDomain?: boolean, // flag for add new domain
    newDomain?: string,
    upgradePopup?: boolean,
    forNewPurchase?:boolean
}


// component to render plan/pricing list
const UpgradePlanList = (props: PropTypes) => {

    // Redux Data
    const { currentWebsitePlanList, user, activeLanguage, accessToken, currentUserDiscount, currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Props
    const { activePlanPrice, activePlanInterval, activePlanType, activePlanDomain, forAddNewDomain, newDomain, upgradePopup, forNewPurchase } = props;

    //console all props
    console.log("activePlanPrice", activePlanPrice);
    console.log("activePlanInterval", activePlanInterval);
    console.log("activePlanType", activePlanType);
    console.log("activePlanDomain", activePlanDomain);
    console.log("forAddNewDomain", forAddNewDomain);
    console.log("newDomain", newDomain);
    console.log("upgradePopup", upgradePopup);
    console.log("forNewPurchase", forNewPurchase);


    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [upgradePlanList, setUpgradePlanList] = useState<websitePlanPropType[]>([]);
    const [planInterval, setPlanInterval] = useState<string>();
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [freePlanID, setFreePlanID] = useState<number>();
    const [isFreeWidget, setIsFreeWidget] = useState<boolean>(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [isTrial, setIsTrial] = useState<boolean>(false);
    const [isProPlan, setIsProPlan] = useState<boolean>(false);
    const [isCanceledPlan, setIsCanceledPlan] = useState<boolean>(false);


    const [planPackage, setPlanPackage] = useState<currentPackageOnlyPropType>();


    // Disable the "Select Plan" button for users in department 1
    useEffect(() => {
        // check if admin user
        if (user && user.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [user])

    useEffect(() => {
        setIsFreeWidget(false);
        setIsExpired(false);
        setIsTrial(false);
        setIsProPlan(false);
        setIsCanceledPlan(false);


        if (activePlanDomain && currentWebsite) {
            if (activePlanDomain.is_expired === 1) {
                setIsExpired(true);
            }
            else if (activePlanDomain.is_expired === 3 && activePlanDomain.current_package_only.length) {
                setIsTrial(true);
            }
            else if (activePlanDomain.canceled_package_only && activePlanDomain.canceled_package_only.length) {
                setIsCanceledPlan(true);
            }
            else if (activePlanDomain.current_package_only && activePlanDomain.current_package_only.length) {
                if (activePlanDomain.current_package_only[0].is_free_widget === 1) {
                    setIsFreeWidget(true);
                }
                else if (activePlanDomain.current_package_only[0].cancel_date) {
                    setIsCanceledPlan(true);
                }
                else {
                    setIsProPlan(true);
                }
            }
        }
    }, [activePlanDomain, currentWebsite])

    useEffect(() => {
        if (activePlanDomain) {
            if (activePlanDomain.expired_package_detail && activePlanDomain.expired_package_detail.length) {
                setPlanPackage(activePlanDomain.expired_package_detail[0]);
            }
            else if (activePlanDomain.canceled_package_only && activePlanDomain.canceled_package_only.length) {
                setPlanPackage(activePlanDomain.canceled_package_only[0]);
            }
            else if (activePlanDomain.current_package_only && activePlanDomain.current_package_only.length) {
                setPlanPackage(activePlanDomain.current_package_only[0]);
            }
        }

    }, [activePlanDomain])



    // Filter the plan list based on conditions and exclude plans with is_dynamic === 1
    useEffect(() => {
        console.log("activePlanType === 2", activePlanType === 2);

        // check if plan list available
        if (currentWebsitePlanList && Object.keys(currentWebsitePlanList).length > 0) {
            let tempPlanList: websitePlanPropType[] = [];

            // 1 = widget plan
            if (activePlanType === 1) {
                if (planPackage) {
                    // check if dynamic plan exists
                    if (planPackage.platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });

                        // check if plan list is empty
                        if (!tempPlanList.length) {
                            currentWebsitePlanList[`normal`]
                                .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                                .forEach(planList => {
                                    if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    }
                                });
                        }
                    } else {
                        currentWebsitePlanList[`normal`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            })
                    }

                }
                else {
                    currentWebsitePlanList[`normal`].map((planList) => {
                        if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList]
                        } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList]
                        }
                    })
                }
            }
            // check if 2- multi domain plan
            else if (activePlanType === 2) {
                console.log("planPackage 123456", planPackage);
                if (planPackage) {
                    if (planPackage.platforms === "dynamic") {
                        currentWebsitePlanList[`multi`]
                            .filter(planList => planList.is_dynamic === 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if (planPackage.package_id === planList.id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    } else {
                        currentWebsitePlanList[`multi-store`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                /* console.log(`test: planlist ${planList.monthly_price} ${JSON.stringify(planList, null, 2)}`)
                                console.log(`test: activeplanprice ${activePlanPrice}`) */


                                if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) { //remove =
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    }
                }
            }
            // check if 3 - Pre purchase plan
            else if (activePlanType === 3) {
                if (planPackage) {
                    if (planPackage.platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    } else {

                        // Fall back to multi plans if no eligible dynamic plans are found    
                        if (!tempPlanList.length) {
                            currentWebsitePlanList[`multi`]
                                .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                                .forEach(planList => {
                                    if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    }
                                });
                        }
                    }
                }
                else {
                    currentWebsitePlanList[`multi`]
                        .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                        .forEach(planList => {
                            if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            }
                        });
                }
            }

            setUpgradePlanList(tempPlanList);

        }


    }, [activePlanType, activePlanInterval, activePlanPrice, planPackage]);

    useEffect(() => {
        if (activePlanInterval) {
            setPlanInterval(activePlanInterval);
        }
    }, [activePlanInterval]);

    // Retrieve the ID of the free widget plan
    useEffect(() => {
        if (currentWebsitePlanList && currentWebsitePlanList["free-widget"]) {
            setFreePlanID(currentWebsitePlanList["free-widget"][0].id);
        }

    }, [currentWebsitePlanList])

    // Handle widget plan purchase
    const handlePurchaseWidgetPlan = (planID: number, isTrial: boolean) => {
        // API to save normal website
       /*  console.log("activePlanDomain handlePurchaseWidgetPlan", activePlanDomain);
        console.log("newDomain handlePurchaseWidgetPlan", newDomain);
        console.log("planPackage handlePurchaseWidgetPlan", planPackage); */
        if (accessToken && user && activeLanguage && planID) {
            let iframeURLType: number;
            if (forAddNewDomain) {
                iframeURLType = 1;
            }
            else if (planPackage && planPackage.package_id === planID) {
                if (activePlanDomain && ((activePlanDomain.used_canceled_discount === 1 && isCanceledPlan) || !activePlanDomain.is_from_dashboard || isExpired)) {
                    iframeURLType = 1 // reactivate plan for widget user without discount
                }
                else {
                    iframeURLType = 10 // reactivate plan for widget user with discount
                }
            }
            else {
                iframeURLType = 1 // reactivate plan for widget user without discount
            }
            const navigateToPurchase = () => {
                let domain = "";
                if (newDomain) {
                    domain = newDomain
                }
                else if (activePlanDomain) {
                    domain = activePlanDomain.domain
                }
                console.log("domain", domain);
                const urlString = `${planID}|${domain}|${planInterval}|${user.id}|${activeLanguage.code}|${iframeURLType}${isTrial ? "||1" : ""}`;
                const encodedString = encodeBase64(urlString);
                navigate(`/front/package/${encodedString}`);
            }

            if (forAddNewDomain && newDomain) {
                saveNormalWebsiteApi(accessToken, newDomain, user.id, planID).then((res) => {
                    // check if response data is available
                    console.log(`test: saveNormalWebsiteApi response`, res);
                    if (res.Data) {
                        navigateToPurchase();
                    }
                });
            }
            else {
                navigateToPurchase();
            }


        }
        

        /* if (user && activeLanguage && planID) {
            const urlString = `${planID}|${activePlanDomain}|${planInterval}|${user.id}|${activeLanguage.code}|1${isTrial ? "||1" : ""}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        } */
    }

    // Widget multi-store Plan Purchase Event
    const handleMultiStoreWidgetPlan = (planID: number) => {
        if (user && activeLanguage && planID && activePlanDomain && planPackage) {
            console.log(`planPackage.package_id`, planPackage.package_id);
            console.log(`planID`, planID);
            let iframeURLType: number;
            if (forNewPurchase) {
                iframeURLType = 5 // purchase a new plan for multi site partner user
            }
            else if (planPackage.package_id === planID) {
                if ((activePlanDomain.used_canceled_discount === 1 && isCanceledPlan) || !activePlanDomain.is_from_dashboard || isExpired) {
                    iframeURLType = 52 // reactivate plan for multi site partner user without discount
                }
                else {
                    iframeURLType = 51 // reactivate plan for multi site partner user with discount
                }
            }
            else {
                iframeURLType = 52 // reactivate plan for multi site partner user with discount
            }
            const urlString = `${planID}|${`multi-store`}|${planInterval}|${user.id}|${activeLanguage.code}|${iframeURLType}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Handle multi-domain widget plan purchase 
    const handleMultiDomainWidgetPlan = (planID: number) => {


        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${`multi`}|${planInterval}|${user.id}|${activeLanguage.code}|${3}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Handle purchase of a normal domain plan for adding a new domain
    const handlePurchaseNormalDomainPlan = (planID: number) => {
        console.log("test: handlePurchaseNormalDomainPlan is called");
        let domain = "";
        if (newDomain) {
            domain = newDomain
        }
        else if (activePlanDomain) {
            domain = activePlanDomain.domain
        }
        console.log("domain", domain);
        if (accessToken && user && planID && activeLanguage && planInterval && domain) {
            saveNormalDomainService(accessToken, domain, user.id, planID, planInterval, activeLanguage.code, planID === freePlanID, dispatch, navigate, t)
        }
    }

    const handlePurchase = (planID: number) => {
        //console.log("handlePurchase plan id", planID);
        switch (activePlanType) {
            case 1:
                handlePurchaseWidgetPlan(planID, false);
                break;
            case 2:
                handleMultiStoreWidgetPlan(planID);
                break;
            case 3:
                if (activePlanPrice === 0) {
                    handleMultiDomainWidgetPlan(planID);
                }
                else {
                    handleMultiStoreWidgetPlan(planID);
                }
                break;
            default:
        }
    }

    useEffect(() => {
        if (planPackage && activePlanInterval) {
            if (activePlanInterval === "M" && (planPackage.slug === "large-site-10-domains" || planPackage.slug === "extra-large-site" || planPackage.slug === "platinum")) {
                setPlanInterval("Y");
            }
        }
    }, [planPackage, activePlanInterval])



    return (
        <>
            {console.log("isCanceledPlan", isCanceledPlan)}
            {console.log("isExpired", isExpired)}
            {console.log("upgradePlanList", upgradePlanList)}
            <div className="aioa_dashboard-widget-plan-list">
                <>
                    {
                        ((forNewPurchase || (activePlanInterval === "M" || activePlanInterval === null))
                            && !((planPackage?.slug === "large-site-10-domains" || planPackage?.slug === "extra-large-site" || planPackage?.slug === "platinum") && activePlanInterval === "Y"))
                        && (
                            <>
                                <div className={`aioa_dashboard-plan-list-toggle ${forAddNewDomain ? 'justify-content-center' : ""}`}>
                                    <span className={`toggle-label monthly ${planInterval === 'M' ? `active` : ``}`} role="button" onClick={() => setPlanInterval('M')}>{t('Pay Monthly')}</span>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        aria-label="Toggle Annual Plan"
                                        defaultChecked={false}
                                        checked={planInterval === 'Y'}
                                        onChange={() => setPlanInterval(planInterval === 'M' ? 'Y' : 'M')}
                                    />
                                    <span className={`toggle-label annually ${planInterval === 'Y' ? `active` : ``}`} role="button" onClick={() => setPlanInterval('Y')}>
                                        {t('Pay Annually')}
                                        <span className="discount">
                                            Save 20%
                                            {/* {t("Save",{percentage: `20%`})} */}
                                        </span>
                                    </span>
                                    {/* <ul>
                                        <li><Button className={`${planInterval === 'M' ? `active` : ``}`} variant={`${planInterval === 'M' ? `primary` : `secondary`}`} onClick={() => setPlanInterval('M')}>{t('Pay Monthly')}</Button></li>
                                        <li><Button className={`${planInterval === 'Y' ? `active` : ``}`} variant={`${planInterval === 'Y' ? `primary` : `secondary`}`} onClick={() => setPlanInterval('Y')}>{t('Pay Yearly')}</Button></li>
                                    </ul> */}
                                </div>
                            </>
                        )}
                </>



                <Row className="gx-2 gy-4"
                    xs={1}
                    md={2}
                    xl={(forAddNewDomain && user && user.department !== 4) ? ((upgradePlanList.length + 1) < 4 ? 4 : (upgradePlanList.length + 1)) : (upgradePlanList.length < 4 ? (isFreeWidget ? 5 : 4) : isFreeWidget ? upgradePlanList.length + 1 : upgradePlanList.length)}
                >
                    {(upgradePlanList && upgradePlanList.length > 0) ? (
                        <>
                            {(forAddNewDomain && user && user.department !== 4) ?
                                <Col>
                                    <Card className="aioa_dashboard-widget-plan-box">
                                        <Card.Header className="h5">
                                            <div className="aioa_dashboard-widget-plan-box-name">
                                                <span>
                                                    {t("Free Widget")}</span>
                                            </div>
                                            <div className="aioa_dashboard-widget-plan-box-price">
                                                <div className="aioa_dashboard-widget-plan-box-offer-price">$0<span>/{planInterval === "M" ? t('Per Month') : t('Per Year')}</span>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="aioa_dashboard-widget-plan-box-description-wrapper">
                                                <div className="aioa_dashboard-widget-plan-box-description">{t('Plan Description Text', { pageViews: 10 })}</div>
                                            </div>

                                            <div className="aioa_dashboard-widget-plan-box-btn">
                                                {freePlanID ? <Button variant="primary" size="lg" className="w-100" onClick={() => handlePurchaseNormalDomainPlan(freePlanID)}>{t('Select Plan')}</Button> : ""}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                : <></>
                            }

                            {(!forAddNewDomain && isFreeWidget && !upgradePopup) ?
                                <Col>
                                    <Card className="aioa_dashboard-widget-plan-box current">
                                        <Card.Header className="h5">
                                            <div className="aioa_dashboard-widget-plan-box-name">
                                                <span>{t("Free Widget")}</span>
                                                <div className="status active">{t("Active")}</div>
                                            </div>
                                            <div className="aioa_dashboard-widget-plan-box-price">
                                                <div className="aioa_dashboard-widget-plan-box-offer-price">$0<span>/{planInterval === "M" ? t('Per Month') : t('Per Year')}</span>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="aioa_dashboard-widget-plan-box-description-wrapper">
                                                <div className="aioa_dashboard-widget-plan-box-description">{t('Plan Description Text', { pageViews: 10 })}</div>
                                            </div>

                                            <div className="aioa_dashboard-widget-plan-box-btn">
                                                <span className="btn btn-lg btn-warning w-100 pe-none">
                                                    {t("Current Plan")}
                                                </span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                : <></>
                            }

                            {activePlanDomain && upgradePlanList.map((planItem) => {
                                return (
                                    <Col key={planItem.id} className={`${(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && upgradePopup) ? 'd-none' : ''}`}>
                                        <Card className={`aioa_dashboard-widget-plan-box 
                                        ${((isProPlan || isTrial) && planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice) ? 'active' : ''} 
                                        ${(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice) ? 'current' : ''}`}>
                                            <Card.Header className="h5">
                                                <div className="aioa_dashboard-widget-plan-box-name">
                                                    <span>
                                                        {planItem.name}
                                                    </span>
                                                    {isProPlan && <div className="status active">{t("Active")}</div>}
                                                    {isTrial && <div className="status trial">{t("trial")}</div>}
                                                    {isCanceledPlan && <div className="status cancelled">{t("Cancelled")}</div>}
                                                    {isExpired && <div className="status expired">{t("Expired")}</div>}
                                                </div>
                                                <div className="aioa_dashboard-widget-plan-box-price">
                                                    {user && user.department === 4 && currentUserDiscount && currentUserDiscount.discount_value > 0 ? (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    {/* <div className="aioa_dashboard-widget-plan-box-strike-price"><del>${planItem.monthly_price.toFixed(2)}<span>/{t('Per Month')}</span></del></div> */}
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        <del className="me-2"><small>${(planItem.monthly_price).toFixed(2)}</small></del>
                                                                        $<StrikePrice price={planItem.monthly_price} />
                                                                        <span>/{t('Per Month')}</span></div>

                                                                </>
                                                            ) : (
                                                                <>
                                                                    {/* <div className="aioa_dashboard-widget-plan-box-strike-price">
                                                                        <del>
                                                                            ${planItem.price.toFixed(2)}
                                                                            <span>/{t('Per Year')}</span>
                                                                        </del>
                                                                    </div> */}

                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        <del className="me-2"><small>${(planItem.price).toFixed(2)}</small></del>
                                                                        $<StrikePrice price={planItem.price} />
                                                                        <span>/{t('Per Year')}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        {activePlanDomain ?
                                                                            <>  {isCanceledPlan ?
                                                                                <>
                                                                                    {(activePlanDomain.used_canceled_discount === 0 && !isFreeWidget && activePlanDomain.canceled_package_only && activePlanDomain.canceled_package_only.length && activePlanDomain.is_from_dashboard)
                                                                                        ?
                                                                                        <>
                                                                                            {(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && planPackage)
                                                                                                ? <>
                                                                                                    <del className="me-2"><small>${(planItem.monthly_price).toFixed(2)}</small></del>
                                                                                                    {activePlanDomain.is_cancel_offer_accept === 1 ?
                                                                                                        <>
                                                                                                            $<StrikePrice price={planItem.monthly_price} isDiscountPr={true} discountPr={activePlanDomain.cancel_discount_percentage} />
                                                                                                        </>
                                                                                                        : <>
                                                                                                            $<StrikePrice price={planItem.monthly_price} isDiscountPr={true} discountPr={activePlanDomain.reactivate_discount} />
                                                                                                        </>

                                                                                                    }
                                                                                                </>
                                                                                                : <>
                                                                                                    $<StrikePrice price={planItem.monthly_price} />
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                        : <>
                                                                                            {(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && planPackage)
                                                                                                ? <>
                                                                                                    ${(planPackage.final_price).toFixed(2)}
                                                                                                </>
                                                                                                : <>
                                                                                                    ${(planItem.monthly_price).toFixed(2)}
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }

                                                                                </>
                                                                                : <>
                                                                                    {planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && planPackage
                                                                                        ? <>
                                                                                            ${(planPackage.final_price).toFixed(2)}
                                                                                        </>
                                                                                        : <>
                                                                                            ${(planItem.monthly_price).toFixed(2)}
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            </>
                                                                            : <>
                                                                            </>
                                                                        }
                                                                        <span>/{t('Per Month')}</span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        {activePlanDomain ?
                                                                            <>  {isCanceledPlan ?
                                                                                <>
                                                                                    {(activePlanDomain.used_canceled_discount === 0 && !isFreeWidget && activePlanDomain.canceled_package_only && activePlanDomain.canceled_package_only.length && activePlanDomain.is_from_dashboard)
                                                                                        ?
                                                                                        <>
                                                                                            {(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && planPackage)
                                                                                                ? <>
                                                                                                    <del className="me-2"><small>${(planItem.price).toFixed(2)}</small></del>
                                                                                                    {activePlanDomain.is_cancel_offer_accept === 1 ?
                                                                                                        <>
                                                                                                            $<StrikePrice price={planItem.price} isDiscountPr={true} discountPr={activePlanDomain.cancel_discount_percentage} />
                                                                                                        </>
                                                                                                        : <>
                                                                                                            $<StrikePrice price={planItem.price} isDiscountPr={true} discountPr={activePlanDomain.reactivate_discount} />
                                                                                                        </>

                                                                                                    }
                                                                                                </>
                                                                                                : <>
                                                                                                    $<StrikePrice price={planItem.price} />
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                        : <>
                                                                                            {(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && planPackage)
                                                                                                ? <>
                                                                                                    ${(planPackage.final_price).toFixed(2)}
                                                                                                </>
                                                                                                : <>
                                                                                                    ${(planItem.price).toFixed(2)}
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }

                                                                                </>
                                                                                : <>
                                                                                    {planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice && planPackage
                                                                                        ? <>
                                                                                            ${(planPackage.final_price).toFixed(2)}
                                                                                        </>
                                                                                        : <>
                                                                                            ${(planItem.price).toFixed(2)}
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            </>
                                                                            : <>
                                                                            </>
                                                                        }
                                                                        <span>/{t('Per Year')}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {((isProPlan || isTrial) && planPackage && planPackage.subscr_interval === planInterval && planItem.monthly_price === activePlanPrice)
                                                    ? <>

                                                        <div className="aioa_dashboard-widget-plan-box-renewal-badge">
                                                            {/* {t("Next Renewal on", { date: parseISODate(planPackage.end_date) })} */}
                                                            Next Renewal on {parseISODate(planPackage.end_date)}
                                                        </div>
                                                        <div className="aioa_dashboard-widget-plan-box-divider"></div>
                                                    </>
                                                    : <></>
                                                }

                                                {(isCanceledPlan && activePlanDomain?.canceled_package_only.length && planPackage && planPackage.subscr_interval === planInterval && planItem.monthly_price === activePlanPrice)
                                                    ? <>

                                                        <div className="aioa_dashboard-widget-plan-box-renewal-badge">
                                                            {/* {t("Cancelled on", { date: parseISODate(planPackage.cancel_date) })} */}
                                                            Cancelled on {parseISODate(planPackage.cancel_date)}
                                                        </div>
                                                        <div className="aioa_dashboard-widget-plan-box-divider"></div>
                                                    </>
                                                    : <></>
                                                }

                                            </Card.Header>
                                            <Card.Body>
                                                <div className="aioa_dashboard-widget-plan-box-description-wrapper">
                                                    <div className="aioa_dashboard-widget-plan-box-description">
                                                        {t('Plan Description Text', { pageViews: planItem.page_views })}
                                                    </div>


                                                    {activePlanType === 2 && (
                                                        <>
                                                            <div className="aioa_dashboard-widget-plan-domain-description">
                                                                {t('plan domain info', { domainCount: planItem.slug === 'silver' ? 3 : planItem.slug === 'gold' ? 5 : planItem.domain_limit ? planItem.domain_limit : 10 })}
                                                            </div>
                                                        </>
                                                    )}

                                                    {activePlanType === 3 && (
                                                        <>
                                                            <div className="aioa_dashboard-widget-plan-domain-description">
                                                                {t('plan domain info', { domainCount: planItem.domain_limit || 0 })}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>


                                                <div className="aioa_dashboard-widget-plan-box-btn">
                                                    {forAddNewDomain ? (
                                                        <>
                                                            {activePlanType === 1 ? (
                                                                <>
                                                                    <Button variant="primary" size="lg" className="w-100" onClick={() => handlePurchaseNormalDomainPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                    {user?.department === 4 ? <>
                                                                        <div className="pt-3 mt-3 border-top text-center">
                                                                            <a role="button"
                                                                                onClick={() => handlePurchaseWidgetPlan(planItem.id, true)}
                                                                                className={`${disabledButton ? `pe-none` : ``}`}
                                                                                aria-disabled={`${disabledButton ? `true` : `false`}`}
                                                                                tabIndex={disabledButton ? -1 : 0}
                                                                            >
                                                                                {t('free trial btn label')}
                                                                            </a>
                                                                        </div>
                                                                    </> : <></>}

                                                                </>
                                                            ) : (
                                                                <>

                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(activePlanDomain && planPackage && planPackage.subscr_interval === planInterval && planItem.monthly_price === activePlanPrice && planPackage) ?
                                                                <>
                                                                    {isCanceledPlan &&
                                                                        <>
                                                                            {(activePlanDomain.canceled_package_only && activePlanDomain.canceled_package_only.length > 0)
                                                                                ? <>
                                                                                    <Button
                                                                                        variant="primary"
                                                                                        size="lg"
                                                                                        className="w-100"
                                                                                        onClick={() => {
                                                                                            handlePurchase(planItem.id)
                                                                                        }}
                                                                                        disabled={disabledButton}>
                                                                                        {t('Reactivate Now')}
                                                                                    </Button>
                                                                                </>
                                                                                : <span className="btn btn-lg btn-danger w-100 pe-none">
                                                                                    Expires on {parseISODate(planPackage.end_date)}
                                                                                    {/* {t("Expires on", { date: parseISODate(planPackage.end_date) })} */}
                                                                                </span>
                                                                            }

                                                                        </>

                                                                    }
                                                                    {isExpired &&
                                                                        <Button
                                                                            variant="primary"
                                                                            size="lg"
                                                                            className="w-100"
                                                                            onClick={() => {
                                                                                handlePurchase(planItem.id)
                                                                            }}
                                                                            disabled={disabledButton}>
                                                                            {t('Renew Now')}
                                                                        </Button>
                                                                    }
                                                                    {(isProPlan) &&
                                                                        <PaymentButtonBlock
                                                                            buttonType={3}
                                                                            activePlanType={activePlanType}
                                                                            activePlanDomain={activePlanDomain}
                                                                            activePlanInterval={planPackage.subscr_interval}
                                                                            activePlanPrice={planPackage.final_price}
                                                                            activePlanOfferPrice={activePlanDomain.cancel_discount_price}
                                                                            activePlanOfferPr={activePlanDomain.cancel_discount_percentage}
                                                                            websiteID={activePlanDomain.id}
                                                                            activeUserID={activePlanDomain.user_id}
                                                                            activePlanID={planPackage.package_id}
                                                                            buttonBigSize={true}
                                                                            buttonVariant={`danger`}
                                                                            buttonLabel={`Cancel Plan`}
                                                                            isShowDiscount={false}
                                                                        />

                                                                    }

                                                                    {(isTrial) &&
                                                                        <>
                                                                            <PaymentButtonBlock
                                                                                buttonType={3}
                                                                                activePlanType={activePlanType}
                                                                                activePlanDomain={activePlanDomain}
                                                                                activePlanInterval={planPackage.subscr_interval}
                                                                                activePlanPrice={planPackage.final_price}
                                                                                activePlanOfferPrice={activePlanDomain.cancel_discount_price}
                                                                                activePlanOfferPr={activePlanDomain.cancel_discount_percentage}
                                                                                websiteID={activePlanDomain.id}
                                                                                activeUserID={activePlanDomain.user_id}
                                                                                activePlanID={planPackage.package_id}
                                                                                buttonBigSize={true}
                                                                                buttonVariant={`danger`}
                                                                                buttonLabel={`Cancel Plan`}
                                                                                isShowDiscount={(user && user.department !== 4 && activePlanDomain && activePlanDomain.current_package_only && activePlanDomain.current_package_only.length && activePlanDomain.current_package_only[0].is_cancel_offer_accept !== 1) ? true : false}
                                                                            />
                                                                        </>

                                                                    }
                                                                </>
                                                                : <Button variant="primary" size="lg" className="w-100" onClick={() => handlePurchase(planItem.id)} disabled={disabledButton}>
                                                                    <>
                                                                    {(activePlanPrice === 0 || isExpired) ? t('Purchase Now') : t('Upgrade Plan')}
                                                                    </>
                                                                    
                                                                    {/* {(activePlanType === 3 && user && user.is_multiple_domain && !user.is_multi_domain_purchased)
                                                                        ? <>
                                                                            {(activePlanPrice === 0) ? t('Purchase Now') : t('Upgrade Plan')}
                                                                        </>
                                                                        : <>
                                                                            {currentWebsitePlanStatus.isExpired || currentWebsitePlanStatus.isCanceledPlan ? t('Purchase Now') : t('Upgrade Plan')}
                                                                        </>
                                                                    } */}
                                                                </Button>
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}

                            {!activePlanDomain && upgradePlanList.map((planItem) => {
                                return (
                                    <Col key={planItem.id}>
                                        <Card className='aioa_dashboard-widget-plan-box'>
                                            <Card.Header className="h5">
                                                <div className="aioa_dashboard-widget-plan-box-name">
                                                    <span>
                                                        {planItem.name}
                                                    </span>
                                                </div>
                                                <div className="aioa_dashboard-widget-plan-box-price">
                                                    {user && user.department === 4 && currentUserDiscount && currentUserDiscount.discount_value > 0 ? (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <><div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                    <del className="me-2"><small>${(planItem.monthly_price).toFixed(2)}</small></del><br />
                                                                    $<StrikePrice price={planItem.monthly_price} />
                                                                    <span>/{t('Per Month')}</span></div>

                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        <>
                                                                            <del className="me-2"><small>${(planItem.price).toFixed(2)}</small></del><br />
                                                                            $<StrikePrice price={planItem.price} />
                                                                            <span>/{t('Per Year')}</span>
                                                                        </>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        <>${planItem.monthly_price}</>
                                                                        <span>/{t('Per Month')}</span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        <>${planItem.price}</>
                                                                        <span>/{t('Per Year')}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>

                                            </Card.Header>
                                            <Card.Body>
                                                <div className="aioa_dashboard-widget-plan-box-description-wrapper">
                                                    <div className="aioa_dashboard-widget-plan-box-description">
                                                        {t('Plan Description Text', { pageViews: planItem.page_views })}
                                                    </div>


                                                    {activePlanType === 2 && (
                                                        <>
                                                            <div className="aioa_dashboard-widget-plan-domain-description">
                                                                {t('plan domain info', { domainCount: planItem.slug === 'silver' ? 3 : planItem.slug === 'gold' ? 5 : planItem.domain_limit ? planItem.domain_limit : 10 })}
                                                            </div>
                                                        </>
                                                    )}

                                                    {activePlanType === 3 && (
                                                        <>
                                                            <div className="aioa_dashboard-widget-plan-domain-description">
                                                                {t('plan domain info', { domainCount: planItem.domain_limit || 0 })}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>


                                                <div className="aioa_dashboard-widget-plan-box-btn">
                                                    {forAddNewDomain ? (
                                                        <>
                                                            {activePlanType === 1 ? (
                                                                <>
                                                                    <Button variant="primary" size="lg" className="w-100" onClick={() => handlePurchaseNormalDomainPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                    {user?.department === 4 ? <>
                                                                        <div className="pt-3 mt-3 border-top text-center">
                                                                            <a role="button"
                                                                                onClick={() => handlePurchaseWidgetPlan(planItem.id, true)}
                                                                                className={`${disabledButton ? `pe-none` : ``}`}
                                                                                aria-disabled={`${disabledButton ? `true` : `false`}`}
                                                                                tabIndex={disabledButton ? -1 : 0}
                                                                            >
                                                                                {t('free trial btn label')}
                                                                            </a>
                                                                        </div>
                                                                    </> : <></>}

                                                                </>
                                                            ) : (
                                                                <>

                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button variant="primary" size="lg" className="w-100" onClick={() => handlePurchase(planItem.id)} disabled={disabledButton}>
                                                                {t('Purchase Now')}
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </>
                    ) : <>
                        {activePlanDomain && activePlanDomain.current_package_only && activePlanDomain.current_package_only.length && activePlanDomain.current_package_only[0].slug === "large-site-10-domains"
                            ? t("Contact us at 'hello@skynettechnologies.com'")
                            : t("Contact us to upgrade or purchase a plan.")}
                    </>}
                </Row>
            </div>
        </>
    )
}

export default UpgradePlanList;
