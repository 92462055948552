import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import PaymentButtonBlock from "../PaymentButton";
import { useEffect, useState } from "react";
import AddNewNormalDomain from "../AddNewDomainModal/AddNormalDomain";
import "../../Assets/scss/components/add-new-domain-modal.scss";
import AddNewPrePurchasedDomainModalBlock from "../AddNewDomainModal/AddNewPrePurchasedDomain";
import { useNavigate } from "react-router-dom";

interface PropTypes {
    isShowHeader?: boolean; // flag if displayed in header
}


// component to add new domain
const AddNewDomainButton = (prop: PropTypes) => {
    const { t } = useTranslation();

    // Redux Data
    const dispatch = useDispatch();
    const user = useSelector((store: StoreProptypes) => store.user);
    const currentWebsite = useSelector((store: StoreProptypes) => store.currentWebsite);
    const domainLimitData = useSelector((store: StoreProptypes) => store.domainLimitData);
    const accessToken = useSelector((store: StoreProptypes) => store.accessToken);
    const currentWebsitePlanStatus = useSelector((store: StoreProptypes) => store.currentWebsitePlanStatus);

    const navigate = useNavigate();

    // Props
    const { isShowHeader } = prop;

    // State
    const [isAddNewWebsiteModal, setIsAddNewWebsiteModal] = useState<boolean>(false);
    const [isAddNewPrePurchasedWebsiteModal, setIsAddNewPrePurchasedWebsiteModal] = useState<boolean>(false);
    const [isAddMultiStoreWebsiteModal, setIsAddMultiStoreWebsiteModal] = useState<boolean>(false);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [isBulkDomainModalContent, setIsBulkDomainModalContent] = useState<boolean>(false);

    const totalDomains = user?.website_lists?.length || 0;

    const [isDomainLimitFullyUsed, setIsDomainLimitFullyUsed] = useState<boolean>(true);

    const isExpired = currentWebsitePlanStatus.isExpired

    // const isDynamic = currentWebsite?.current_package_only[0].platforms === "dynamic";
    // console.log(`test: is dynamic ${isDynamic}`)




    // Disabled Button Functions complete dependency
    useEffect(() => {
        if (user?.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
        setIsDomainLimitFullyUsed(user?.is_multi_domain_purchased === 0);
    }, [user]);
    //console.log(`test user ${JSON.stringify(user, null, 4)}`);

    // function to close Add Normal Domain Modal
    const handleCloseAddNewWebsiteModal = () => {
        setIsAddNewWebsiteModal(false);
        setIsBulkDomainModalContent(false);
    };
    // function to show Add Normal Domain Modal
    const handleShowAddNewWebsiteModal = () => setIsAddNewWebsiteModal(true);


    // function to close function to close Pre Purchased Domain Modal
    const handleCloseAddNewPrePurchasedWebsiteModal = () => {
        setIsAddNewPrePurchasedWebsiteModal(false);

    }


    // function to show Pre Purchased Domain Modal
    const handleShowAddNewPrePurchasedWebsiteModal = () => setIsAddNewPrePurchasedWebsiteModal(true);

    // function to close Multi-Store Domain Modal
    const handleCloseAddNewMultiStoreWebsiteModal = () => setIsAddMultiStoreWebsiteModal(false);

    // function to show Multi-Store Domain Modal
    const handleShowAddNewMultiStoreWebsiteModal = () => setIsAddMultiStoreWebsiteModal(true);
    //console.log(`test: user?.is_multi_domain_purchased ${user?.is_multi_domain_purchased} `)

    return (
        <>

            {user && (
                <>
                    <>
                        {console.log("user.department", user.department)}
                        {console.log("user.is_multiple_domain", user.is_multiple_domain)}
                        {console.log("isDomainLimitFullyUsed", isDomainLimitFullyUsed)}
                    </>
                    {user.department === 4 ? ( // check if its a partner user
                        <>
                            {user.is_multiple_domain === 1 ? <>
                                {isDomainLimitFullyUsed ? (
                                    <>
                                        {!isExpired && (
                                            <>
                                                <PaymentButtonBlock
                                                    activePlanDomain={currentWebsite || user?.website_lists[0]}
                                                    activePlanType={3}
                                                    buttonType={1}
                                                    activePlanInterval={"M"}
                                                    activePlanPrice={0}
                                                    buttonVariant={`primary`}
                                                    buttonBigSize={true}
                                                    buttonLabel={`Purchase Now Button`}
                                                    forNewPurchase={true}
                                                />
                                            </>
                                        )}
                                    </>

                                    // If 10 or more domains and in multiples of 10, show Purchase Now Button


                                ) : (
                                    // If less than 10 domains or not in multiples of 10, show Add New Domain Button
                                    <>
                                        {user.is_multiple_domain === 1 ? (
                                            // check if user has multi domain plan
                                            <>
                                                {user.is_multi_domain_purchased === 1 || (currentWebsite?.current_package_only?.length ?? 0) > 0 ? (
                                                    // check If the plan is purchased
                                                    <Button
                                                        variant={isShowHeader ? `outline-primary` : `primary`}
                                                        className="aioa_dashboard-icon-btn"
                                                        size="lg"
                                                        onClick={() => handleShowAddNewPrePurchasedWebsiteModal()}
                                                        disabled={disabledButton}
                                                    >
                                                        <div className="aioa_dashboard-btn-icon">
                                                            <i className="material-symbols-outlined" aria-hidden="true">add</i>
                                                        </div>
                                                        <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                                    </Button>
                                                ) : (
                                                    // If no plan is purchased, show Purchase Now
                                                    <>
                                                        {!isExpired && (
                                                            <>
                                                                <PaymentButtonBlock
                                                                    activePlanDomain={currentWebsite || user?.website_lists[0]}
                                                                    activePlanType={3}
                                                                    buttonType={1}
                                                                    activePlanInterval={"M"}
                                                                    activePlanPrice={0}
                                                                    buttonVariant={`primary`}
                                                                    buttonBigSize={true}
                                                                    buttonLabel={`Purchase Now Button`}
                                                                    forNewPurchase={true}
                                                                    
                                                                />

                                                            </>
                                                        )}
                                                    </>

                                                )}
                                            </>
                                        ) : (
                                            // Normal domain with bulk
                                            <Button
                                                variant={isShowHeader ? `outline-primary` : `primary`}
                                                className="aioa_dashboard-icon-btn"
                                                size="lg"
                                                onClick={() => handleShowAddNewWebsiteModal()}
                                                disabled={disabledButton}
                                            >
                                                <div className="aioa_dashboard-btn-icon">
                                                    <i className="material-symbols-outlined" aria-hidden="true">add</i>
                                                </div>
                                                <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                            </Button>
                                        )}
                                    </>
                                )}

                            </>
                                : <>
                                    <Button
                                        variant={isShowHeader ? `outline-primary` : `primary`}
                                        className="aioa_dashboard-icon-btn"
                                        size="lg"
                                        onClick={() => handleShowAddNewWebsiteModal()}
                                        disabled={disabledButton}
                                    >
                                        <div className="aioa_dashboard-btn-icon">
                                            <i className="material-symbols-outlined" aria-hidden="true">add</i>
                                        </div>
                                        <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                    </Button>
                                </>}

                        </>
                    ) : (
                        <>

                            {user.is_multiple_domain === 2 ? (
                                // if user has multi user - multi domain plan
                                <>
                                    {user.is_multi_domain_purchased === 1 ? (
                                        // check if plan purchased - Silver/Gold Plan
                                        <Button
                                            variant={isShowHeader ? `outline-primary` : `primary`}
                                            className="aioa_dashboard-icon-btn"
                                            size="lg"
                                            onClick={() => handleShowAddNewMultiStoreWebsiteModal()}
                                            disabled={disabledButton}
                                        >
                                            <div className="aioa_dashboard-btn-icon">
                                                <i className="material-symbols-outlined" aria-hidden="true">add</i>
                                            </div>
                                            <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                        </Button>
                                    ) : (
                                        // Handle expired or inactive plans
                                        currentWebsitePlanStatus.isExpired
                                            ? <>
                                                {currentWebsite?.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                                                    <PaymentButtonBlock
                                                        buttonType={4}
                                                        activePlanType={2}
                                                        activePlanDomain={currentWebsite}
                                                        activePlanInterval={currentWebsite.expired_package_detail[0]?.subscr_interval || "M"}
                                                        activePlanPrice={currentWebsite.expired_package_detail[0]?.final_price || 0}
                                                        websiteID={currentWebsite.id || 0}
                                                        activeUserID={currentWebsite.user_id || 0}
                                                        activePlanID={currentWebsite.expired_package_detail[0]?.package_id || 0}
                                                        buttonVariant={`danger`}
                                                        buttonLabel={`Renew Now Button`}
                                                    />
                                                )}
                                            </>
                                            : <>
                                                {currentWebsite?.current_package_only && currentWebsite.current_package_only.length > 0 && (
                                                    <PaymentButtonBlock
                                                        activePlanDomain={currentWebsite || user?.website_lists[0]}
                                                        activePlanType={2}
                                                        buttonType={1}
                                                        activePlanInterval={currentWebsite?.current_package_only[0].subscr_interval || "M"}
                                                        activePlanPrice={0}
                                                        buttonVariant={`primary`}
                                                        buttonBigSize={true}
                                                        buttonLabel={`Purchase Now Button`}
                                                        forNewPurchase={true}

                                                    />

                                                )}
                                                {/* {currentWebsite?.canceled_package_only && currentWebsite.canceled_package_only.length > 0 && (
                                                    <PaymentButtonBlock
                                                        activePlanDomain={currentWebsite || user?.website_lists[0]}
                                                        activePlanType={2}
                                                        buttonType={1}
                                                        activePlanInterval={currentWebsite?.canceled_package_only[0].subscr_interval || "M"}
                                                        activePlanPrice={0}
                                                        buttonVariant={`primary`}
                                                        buttonBigSize={true}
                                                        buttonLabel={`Purchase Now Button`}
                                                        forNewPurchase={true}

                                                    />
                                                )} */}



                                            </>

                                    )}
                                </>
                            ) : (
                                // Default Add New Domain Button
                                <Button
                                    variant={isShowHeader ? `outline-primary` : `primary`}
                                    className="aioa_dashboard-icon-btn"
                                    size="lg"
                                    onClick={() => handleShowAddNewWebsiteModal()}
                                    disabled={disabledButton}
                                >
                                    <div className="aioa_dashboard-btn-icon">
                                        <i className="material-symbols-outlined" aria-hidden="true">add</i>
                                    </div>
                                    <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                </Button>
                            )}
                        </>
                    )}
                </>
            )}

            {/* Normal Domain Add Modal */}
            <Modal
                show={isAddNewWebsiteModal}
                onHide={handleCloseAddNewWebsiteModal}
                size="xl"
                fullscreen="lg-down"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add New Domain")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewNormalDomain isBulkDomainModalContent={isBulkDomainModalContent} />
                </Modal.Body>
            </Modal>

            {/* Pre Purchased Domain Add Modal */}
            <Modal
                show={isAddNewPrePurchasedWebsiteModal}
                onHide={handleCloseAddNewPrePurchasedWebsiteModal}
                size="lg"
                fullscreen="md-down"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add New Domain")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewPrePurchasedDomainModalBlock callBackFunction={handleCloseAddNewPrePurchasedWebsiteModal} />
                </Modal.Body>
            </Modal>

            {/* Multi-Store Domain Modal */}
            <Modal
                show={isAddMultiStoreWebsiteModal}
                onHide={handleCloseAddNewMultiStoreWebsiteModal}
                size="lg"
                fullscreen="md-down"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add New Domain")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewPrePurchasedDomainModalBlock callBackFunction={handleCloseAddNewMultiStoreWebsiteModal} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddNewDomainButton;

