import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import IsVerified from '../../Assets/img/verified.png';
import InVerified from '../../Assets/img/Inactive.png';
import PaymentButtonBlock from "../PaymentButton";
import { useEffect, useState } from "react";

interface PropTypes {
    item: websiteListPropType, // website data object
    srno: number, // serial number 
    handleShowAutoLoginModal: Function, // function to show auto login modal
    handleShowWidgetScriptCodeModal: Function, // function to display widget script modal
    handleSetCurrentWebsite: Function // function to set current website
}

// component to display table cell for website with active plan
const WebsiteListTableCellBlock = (props: PropTypes) => {

    // PropTypes
    const { item, srno, handleShowAutoLoginModal, handleShowWidgetScriptCodeModal, handleSetCurrentWebsite } = props;



    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    /* console.log(`test: user: ${JSON.stringify(user, null, 2)}`); */
    // Functions
    const { t } = useTranslation();

    const [useActivePlanType, setUserActionPlanType] = useState<number>(0);
    const [useActivePlanPrice, setUseActivePlanPrice] = useState<number>(0);


    useEffect(() => {
        if (item && user) {
            const temp = user?.department === 2 && user.is_multiple_domain === 2 ? 2 :
                user?.department === 4 && user?.is_multiple_domain === 1 ? 3 :
                    user?.department === 2 && user?.is_multiple_domain === 0 ? 1 :
                        user?.department === 4 && user?.is_multiple_domain === 0 ? 1 :
                            0;

            setUserActionPlanType(temp);

            const temp1 = item.is_free_widget === 1
                ? 0
                : item.current_package_only[0]?.subscr_interval === 'M'
                    ? item.current_package_only[0]?.package_monthly_price ?? 0
                    : item.current_package_only[0]?.subscr_interval === 'Y'
                        ? item.current_package_only[0]?.package_monthly_price ?? 0
                        : 0; // Ensure a valid number is always returned

            setUseActivePlanPrice(temp1);
        }
    }, [item, user])

    return (
        <>
            {console.log("useActivePlanType", useActivePlanType)}
            {console.log("useActivePlanPrice", useActivePlanPrice)}
            <div className={`aioa_dashboard-table-tr ${user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2 || user.is_multiple_domain === 0) && item.is_show_price === 0 ? `primary-row` : ``}`}>
                {/* check if its a partner user and show price flag is 0 */}
                {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2 || user.is_multiple_domain === 0) && item.is_show_price === 0 && (
                    <>
                        <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                <div className="domain-name">
                                    <div className="primary-domain">
                                        <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                        <div className="text-success">{t('Primary Domain')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Sr No")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
                </div>

                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                        <div className="domain-name">
                            {/* check if website is verified */}
                            {item.is_verified === 1 ? (
                                <>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip data-bs-html="true" id="button-tooltip">
                                                <span className="text-start">{t("Widget is active")}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <div className={`domain-verify-icon`}>
                                            <img src={IsVerified} alt='' />
                                        </div>
                                    </OverlayTrigger>
                                </>
                            ) : (
                                <>
                                    {/* check if widget usage date is available */}
                                    {item.widget_last_use_date ? (
                                        <>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip data-bs-html="true" id="button-tooltip">
                                                        <span className="text-start">{t(`Widget was lastly used on ${parseISODate(item.widget_last_use_date)}`)}</span>
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={`domain-verify-icon`}>
                                                    <img src={InVerified} alt='' />
                                                </div>
                                            </OverlayTrigger>
                                        </>
                                    ) : (
                                        <>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip data-bs-html="true" id="button-tooltip">
                                                        <span className="text-start">{t("Widget activation is pending.")}</span>
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={`domain-verify-icon`}>
                                                    <img src={InVerified} alt='' />
                                                </div>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                </>
                            )}
                            {item.domain}
                            {/* check if its a partner user */}
                            {user &&
                                (
                                    (user.department === 2 && user.is_multiple_domain === 2) ||
                                    (user.department === 4 && user.is_multiple_domain === 1)
                                ) &&
                                item.is_show_price === 0 && (
                                    <>
                                        <div className="primary-domain">
                                            <i className="material-symbols-outlined" aria-hidden="true">check</i>
                                            <div className="text-success">{t('Primary Domain')}</div>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        {/* check if user is admin user */}
                        {(user && user.department === 1) && (
                            <>
                                <div className='email-filed'> <span>{t('Email')}</span> : {item.email}</div>
                            </>
                        )}
                        <div className="link-btn-group">
                            <Button variant="link" onClick={() => handleSetCurrentWebsite(item.id)}>{t('view report btn label')}</Button>

                            {/* check if its a partner user */}
                            {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2 || user.is_multiple_domain === 0) ? (
                                <>
                                    {/* check if show price flag is set to 0 */}
                                    {/* {item.is_show_price === 0 ? (
                                    
                                )} */}
                                    <>
                                        {/* check if domain is verified and last usage date is not available */}
                                        {(item.is_verified !== 1 &&
                                            (item.widget_last_use_date === '' || item.widget_last_use_date === null)) && (
                                                <Button variant="link"
                                                    onClick={() => handleShowWidgetScriptCodeModal(item.widget_position, item.widget_color_code, item.current_package_only[0].api_key, item.url)}>
                                                    {t('Copy Code')}</Button>
                                            )}
                                    </>
                                    {/* ) : (
                                    <>

                                    </> */}
                                </>
                            ) : (
                                <>
                                    {/* check if domain is verified and last usage date is not available */}
                                    {(item.is_verified !== 1 && (item.widget_last_use_date === '' || item.widget_last_use_date === null)) && (
                                        <Button variant="link" onClick={() => handleShowWidgetScriptCodeModal(item.widget_position, item.widget_color_code, item.current_package_only[0].api_key, item.url)}>{t('Copy Code')}</Button>
                                    )}
                                </>
                            )}

                            {/* check if user is admin user */}
                            {(user && user.department === 1) && (
                                <Button variant="link" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                        <div className="domain-name">{item.domain}</div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Widget Status")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Widget Status").toString()}>
                        <div className="domain-name">
                            {/* check if domain is verified */}
                            {item.is_verified === 1 ? (
                                <>
                                    <div>{t("Widget is active")}</div>
                                </>
                            ) : (
                                <>
                                    {/* check if last widget usage date is available */}
                                    {item.widget_last_use_date ? (
                                        <>
                                            <div>{t(`Widget was lastly used on ${parseISODate(item.widget_last_use_date)}`)}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div>{t("Widget activation is pending.")}</div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {/* check if admin user */}
                {(user && user.department === 1) && (
                    <>
                        <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Email")}</div>
                            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Email").toString()}>
                                <div className='email-filed'>{item.email}</div>
                            </div>
                        </div>
                    </>
                )}

                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Plan")}</div>
                    <div
                        className='aioa_dashboard-table-td-cell-value'
                        aria-label={t("Plan").toString()}
                    >
                        {item.current_package_only[0].name}

                        {item.current_package_only[0].name !== "Free Widget" ? <>
                            {(item.current_package_only[0].subscr_interval === "M" ? (
                                <><br />({t("Monthly Plan Type")})</>
                            ) : (
                                <><br />({t("Annually Plan Type")})</>
                            ))}
                        </> : <></>}


                    </div>

                </div>

                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong className="text-success">{t('Active')}</strong></div>
                </div>

                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Price(USD)")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>
                        {/* check if partner user */}
                        {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2 || user.is_multiple_domain === 0) ? (
                            <>
                                {/* check if show price flag is 0 */}
                                {item.is_show_price === 0 ? (
                                    <>
                                        ${item.current_package_only[0].final_price}
                                        {/* <h1>123</h1> */}
                                    </>
                                ) : (
                                    <>
                                        -
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                ${item.current_package_only[0].final_price}
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                    {/* check if its a free widget */}
                    {item.current_package_only[0].is_free_widget ? <>{parseISODate(item.current_package_only[0].start_date)}</> :
                        <>
                            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Renewal Date")}</div>

                            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Renewal Date").toString()}>
                                <div className="aioa_dashboard-table-date-info active-plan-info">
                                    <div className="aioa_dashboard-table-date-label d-none d-xl-block">{t('Renewal Date')}</div>
                                    <div className="aioa_dashboard-table-date">
                                        {parseISODate(item.current_package_only[0].end_date)}
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>
                {/* check if admin user */}
                {user && user.department === 1 ? (
                    <>
                        <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Total/Scan URLs")}</div>
                            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total/Scan URLs").toString()}>{item.total_pages}/{item.total_scan_pages}</div>
                        </div>

                        <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Used Ai Credits")}</div>
                            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Used Ai Credits").toString()}>{item.AiCreditUsedCount}</div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap">
                            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Manage Plan")}</div>
                            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>
                                {user && (user.is_multiple_domain === 2 || user.is_multiple_domain === 1) && item.is_show_price === 1 ? (
                                    <>
                                        -
                                    </>
                                ) : (
                                    <>
                                        {console.log("website item", item)}
                                        {item.current_package_only[0].name !== "Enterprise"
                                            && item.current_package_only[0].name !== "Enterprise Plus"
                                            && item.current_package_only[0].platforms !== "dynamic"
                                            && !(item.current_package_only[0].slug === "large-site-10-domains" && item.current_package_only[0].subscr_interval === "Y")
                                            && !(item.current_package_only[0].slug === "extra-large-site" && item.current_package_only[0].subscr_interval === "Y") 
                                            && !(item.current_package_only[0].slug === "platinum" && item.current_package_only[0].subscr_interval === "Y")
                                            ?
                                            <>

                                                <PaymentButtonBlock
                                                    activePlanDomain={item}
                                                    activePlanType={useActivePlanType}
                                                    buttonType={1}
                                                    activePlanInterval={item.is_free_widget === 1 ? `M` : item.current_package_only[0].subscr_interval}
                                                    activePlanPrice={useActivePlanPrice}
                                                    upgradePopup={true}
                                                />
                                                {/* <h1>{item.current_package_only[0].slug}large-site-10-domains</h1> */}
                                            </>
                                            : <>-</>
                                        }

                                    </>

                                )
                                }
                            </div>
                        </div>
                    </>
                )}

                <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                        <div className="link-btn-group">
                            <Button variant="primary" onClick={() => handleSetCurrentWebsite(item.id)}>{t('view report btn label')}</Button>

                            {/* check if domain is not verified and last usage information is not available */}
                            {(item.is_verified !== 1 && (item.widget_last_use_date === '' || item.widget_last_use_date === null)) && (
                                <Button variant="primary" onClick={() => handleShowWidgetScriptCodeModal(item.widget_position, item.widget_color_code, item.current_package_only[0].api_key, item.url)}>{t('Copy Code')}</Button>
                            )}
                            {(user && user.department === 1) && (
                                <Button variant="primary" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default WebsiteListTableCellBlock;