import { useTranslation } from "react-i18next";
import { UserOrderItemPropTypes } from "../../PropTypes/UserOrderDataPropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button } from "react-bootstrap";
import OrderListCellActionButtons from "./OrderListCellActionButtons";
import PaymentButtonBlock from "../PaymentButton";

interface PropTypes {
    item: UserOrderItemPropTypes, // order object
    index: number, // index
    totalOrderItems: number, // total order items
    handleShowChildDomainListPopup: Function // function to display domain list for an order. (for multi-domain plans)
}

// component to render widget order
const WidgetPlanOrderCellBlock = (props: PropTypes) => {

    // PropTypes
    const { item, index, totalOrderItems, handleShowChildDomainListPopup } = props;

    // Functions
    const { t } = useTranslation();

    // function to generate order ID number
    const generateOrderID = (
        itemID: number // order ID
    ) => {
        if (itemID) {
            const temID = [...Array((5 - ((item.id).toString().length)))].map(() => 0);
            return `${temID}${itemID}`;
        }
    };


    return (
        <>
            <div className="aioa_dashboard-table-tr">
                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Orders #")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Orders #").toString()}>
                        {generateOrderID(item.id)}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Description")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Description").toString()}>
                        {item.is_free_widget === 1 ? (
                            <>
                                <div className="aioa_dashboard-order-plan-details">
                                    <strong>{t('Plan Table Label')}</strong> : {item.name}
                                </div>
                                <div className="aioa_dashboard-order-domain">
                                    <strong>{t('Domain')}</strong> : {item.domain}
                                </div>
                            </>
                        ) : (item.domain_limit > 1 || (item.child_domains && item.child_domains.length > 0)) ? (
                            <>
                                <div className="aioa_dashboard-order-plan-details">
                                    {item.name ? (
                                        <>
                                            <strong>{t('Plan Table Label')}</strong> : {item.name}
                                            {(item.subscr_interval === 'M' ? (
                                                <>
                                                    &nbsp;<strong className="text-primary">({t("Monthly Plan Type")})</strong>
                                                </>
                                            ) : (
                                                <>
                                                    &nbsp;<strong className="text-primary">({t("Annually Plan Type")})</strong>
                                                </>
                                            ))}
                                            {item.is_trial_period === 1 && (<>&nbsp;- <strong className="text-danger">{t("Trial Plan Label")}</strong> </>)}
                                        </>
                                    ) : null}
                                </div>
                                <div className="aioa_dashboard-order-domain">
                                    {item.name ? (
                                        <>
                                            <Button variant="link" onClick={() => handleShowChildDomainListPopup(item.child_domains, item.id)}><strong>{item.domain_limit ? item.domain_limit : (item.child_domains && item.child_domains.length)} {t('Domains Plan')}</strong></Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button variant="link" onClick={() => handleShowChildDomainListPopup(item.child_domains, item.id, 2)}><strong>{t(`order domain list`, { childDomain: item.child_domains && item.child_domains.length > 0 && item.child_domains.length })}</strong></Button>
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="aioa_dashboard-order-plan-details">
                                    <strong>{t('Plan Table Label')}</strong> : {item.name}
                                    {(item.subscr_interval === 'M' ? (
                                        <>
                                            &nbsp;<strong className="text-primary">({t("Monthly Plan Type")})</strong>
                                        </>
                                    ) : (
                                        <>
                                            &nbsp;<strong className="text-primary">({t("Annually Plan Type")})</strong>
                                        </>
                                    ))}
                                    {item.is_trial_period === 1 && (<>&nbsp;- <strong className="text-danger">{t("Trial Plan Label")}</strong> </>)}
                                </div>
                                <div className="aioa_dashboard-order-domain">
                                    <strong>{t('Domain')}</strong> : {item.domain}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Orders Date")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Orders Date").toString()}>
                        {item.start_date ? parseISODate(item.start_date) : '-'}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Amount (USD)")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Amount (USD)").toString()}>
                        {(item.platform && (item.platform).toLowerCase() === 'wix') || (item.platform && (item.platform).toLowerCase() === 'shopify') ? (
                            <>
                                {t('Manage by ')}{item.platform}
                            </>
                        ) : (item.is_free_widget === 1 || item.is_trial_period === 1) ? (
                            <>
                                $0.00
                            </>
                        ) : (
                            <>
                                ${((item.final_price)).toFixed(2)}
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}>
                        {(item.platform && (item.platform).toLowerCase() === 'wix') || (item.platform && (item.platform).toLowerCase() === 'shopify') ? (
                            <>
                                {t('Manage by ')}{item.platform}
                            </>
                        ) : (item.is_trial_period === 1 && item.cancel_reason_id === 0 && item.payment_status === 'Canceled') ? (
                            <>
                                <div className='text-warning'>
                                    <strong>{t('Converted')}</strong>
                                </div>
                            </>
                        ) : (item.payment_status === "Failed") ? (
                            <>
                                <Button className="aioa_dashboard-icon-btn">
                                    <div className="aioa_dashboard-btn-icon"><i className={`material-symbols-outlined`}>info</i></div>
                                    <div className="aioa_dashboard-btn-text">{t("Payment Failed")}</div>
                                </Button>
                            </>
                        ) : (item.payment_status === "Canceled") ? (
                            <>
                                <div className='text-success'>
                                    <strong>{t("Paid Payment Status")}</strong>&nbsp;
                                    <span className='text-danger'>({t('canceled label')})</span>
                                </div>
                                <div className="text-danger"><strong>{item.cancel_date ? parseISODate(item.cancel_date) : '-'}</strong></div>
                            </>
                        ) : (
                            <>
                                <div className='text-success'><strong>{t("Paid Payment Status")}</strong></div>
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                        {(item.platform && (item.platform).toLowerCase() === 'wix') || (item.platform && (item.platform).toLowerCase() === 'shopify') ? (
                            <>
                                {t('Manage by ')}{item.platform}
                            </>
                        ) : (item.is_free_widget === 1) ? (
                            <>
                                -
                            </>
                        ) : item.is_trial_period === 1 ? (
                            <>
                                {item.payment_status !== 'Canceled' && (item.is_pre_trial !== 1) ? (
                                    <>
                                       {/*  <PaymentButtonBlock
                                            buttonType={2}
                                            activePlanType={1}
                                            activePlanDomain={item}
                                            activePlanInterval={item.subscr_interval}
                                            activePlanPrice={item.final_price}

                                            websiteID={item.website_id}
                                            activeUserID={item.user_id}
                                            activePlanID={item.package_id}
                                            buttonBigSize={false}
                                        /> */}
                                    </>
                                ) : (
                                    <>
                                        -
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <OrderListCellActionButtons
                                    orderItemIndex={index}
                                    orderItemID={item.id}
                                    paymentStatus={`${item.payment_status}`}
                                    purchaseURL={`${item.purchase_url}`}
                                    totalOrderItems={totalOrderItems}
                                />
                            </>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

export default WidgetPlanOrderCellBlock;
