import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "../../Assets/scss/components/custom-date-range-picker.scss";

interface PropTypes {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  onClear?: () => void; // Optional prop for clearing the date range
}

const DateRangeFilterBlock = (props: PropTypes) => {
  const { startDate, setStartDate, endDate, setEndDate, onClear } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [customRange, setCustomRange] = useState({
    startDate: "",
    endDate: "",
  });

  const predefinedRanges = [
    { label: "Today", startDate: new Date().toISOString().slice(0, 10), endDate: new Date().toISOString().slice(0, 10) },
    { label: "Yesterday", startDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10), endDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10) },
    { label: "Last 7 Days", startDate: new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().slice(0, 10), endDate: new Date().toISOString().slice(0, 10) },
    { label: "This Month", startDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, "0")}-01`, endDate: `${new Date(new Date().getFullYear(), new Date().getMonth() + 1).toISOString().split("T")[0]}` },
    { label: "Last Month", startDate: `${new Date().getFullYear()}-${new Date().getMonth().toString().padStart(2, "0")}-01`, endDate: `${new Date(new Date().getFullYear(), new Date().getMonth()).toISOString().split("T")[0]}` },
  ];

  const handlePredefinedRangeClick = (range: any) => {
    setStartDate(range.startDate);
    setEndDate(range.endDate);
    setIsDropdownOpen(false);
  };

  const handleApplyClick = () => {
    setStartDate(customRange.startDate);
    setEndDate(customRange.endDate);
    setIsDropdownOpen(false);
  };

  const onClearRange = () => {
    setStartDate("");
    setEndDate("");
    setCustomRange({ startDate: "", endDate: "" });

    if (onClear) onClear(); // Call the parent onClear function if provided
  };

  return (
    <div className="date-range-wrapper">
      <div className="date-range-container">
        <Dropdown
          show={isDropdownOpen}
          onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
          className="date-range-picker"
        >
          <Dropdown.Toggle className="dropdown-toggle custom-dropdown">
            {startDate && endDate
              ? `${startDate} to ${endDate}`
              : "Select Date Range"}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              className="dropdown-arrow"
              width="10"
              height="10"
              style={{ marginLeft: "10px" }}
            >
              <path
                fill="none"
                stroke="#343a40"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m2 5 6 6 6-6"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="predefined-ranges">
              {predefinedRanges.map((range, idx) => (
                <Dropdown.Item
                  key={idx}
                  className="range-item"
                  onClick={() => handlePredefinedRangeClick(range)}
                >
                  {range.label}
                </Dropdown.Item>
              ))}
              {/* Always render the Custom Range */}
              <div className="custom-range-container">
                <fieldset>
                <legend className="custom-range-title">Custom Range</legend>
                <div className="custom-range-fields">
                  <div>
                    <label htmlFor="start-date">Start Date</label>
                    <input
                      id="start-date"
                      type="date"
                      value={customRange.startDate}
                      onChange={(e) =>
                        setCustomRange((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="end-date">End Date</label>
                    <input
                      id="end-date"
                      type="date"
                      value={customRange.endDate}
                      onChange={(e) =>
                        setCustomRange((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                </fieldset>
                <div className="button-group">
                  <button className="apply-btn" onClick={handleApplyClick}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {startDate && endDate && (
          <button className="clear-btn" onClick={onClearRange}>
            Reset
          </button>
        )}
      </div>
    </div>
  );
};

export default DateRangeFilterBlock;

