import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup, Button, Spinner, Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { SET_MESSAGE } from "../../Actions/Types";
import { updateUserService } from "../../Services/UserAction/UpdateUserDetails";

// component to display edit password form fields
const EditPassword = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { user, accessToken, activeLanguage } = useSelector((store: StoreProptypes) => store);


    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCpassword] = useState('');

    const [loading, setLoading] = useState(false);

    const [passCollapse, setPassCollapse] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault(); // Prevent default behavior (e.g., spacebar scrolling)
          setPassCollapse(!passCollapse);
        }
      };


    useEffect(() => {
        if (user) {
            user.name && setFullName(user.name);
            user.email && setUserEmail(user.email);
            user.company_name && setCompanyName(user.company_name);
            user.phone && setPhone(user.phone);
        }
    }, [user])

    // function to handle form submit
    const HandleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (password === '') {
            //focusPasswordInput();
            // dispatch blank password error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("blank password msg", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }
        // check if password length is not correct
        else if (password && (password.length < 8 || password.length > 25)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Password should be between 8 to 25 letters", { returnObjects: true }),
                    "toast_type": "danger"
                }
            })
        }
        // check if new password and confirm password fields are not matching
        else if (cPassword && password && (password !== cPassword)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Password and confirm password doesn't match", { returnObjects: true }),
                    "toast_type": "danger"
                }
            })
        }

        else {
            // check if user data and access token are available, then call user update service
            user && accessToken && updateUserService(
                {
                    ...user,
                    name: fullName,
                    phone: phone,
                    email: userEmail,
                    company_name: companyName,
                    website: '',
                },
                accessToken,
                setLoading,
                dispatch,
                t,
                password
            )
        }
    }

    return (
        <>
            <Form>
                <div className="card mt-4">
                    <div className="card-header">
                        <h2 className="h6 caption caption-with-filter m-0" style={{ padding: '0' }}>
                            <span className="caption-text d-flex justify-content-between w-100"
                            onKeyDown={handleKeyDown} 
                            role="button" 
                            tabIndex={0} 
                            aria-expanded={passCollapse} 
                            onClick={() => setPassCollapse(!passCollapse)} 
                            style={{ cursor: 'pointer', padding: '0' }}>
                                <span >
                                    {t("Change Password")}
                                </span>
                                <span className='violations-collapse-arrow d-flex h-100'>
                                    {passCollapse ? (
                                        <>
                                            <i className={`material-symbols-outlined`} aria-hidden="true">expand_less</i>
                                        </>
                                    ) : (
                                        <>
                                            <i className={`material-symbols-outlined`} aria-hidden="true">expand_more</i>
                                        </>
                                    )}
                                </span>
                            </span>

                        </h2>
                    </div>
                    <Collapse in={passCollapse}>
                        <div className="card-body">
                            <Form.Label htmlFor="password"> {t("New Password")} <span className="text-danger">*</span> </Form.Label>
                            <InputGroup className="mb-3 input-group-outline">
                                <Form.Control
                                    size="lg"
                                    id="password"
                                    type="password"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} value={password}
                                />
                            </InputGroup>
                            <Form.Label htmlFor="cpassword"> {t("Confirm Password")} <span className="text-danger">*</span> </Form.Label>
                            <InputGroup className="mb-3 input-group-outline">
                                <Form.Control
                                    size="lg"
                                    id="cpassword"
                                    type="password"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCpassword(e.target.value)} value={cPassword}
                                />
                            </InputGroup>
                            <div className="text-center">
                                <div className="text-center">
                                    <Button type="submit" variant="primary" size="lg" className={activeLanguage?.is_rtl ? 'me-auto' : 'ms-auto'} onClick={(e) => HandleSubmit(e)} disabled={loading}>
                                        <>
                                            {loading && <Spinner className={activeLanguage?.is_rtl ? 'ms-1' : 'me-1'} animation="grow" variant="light" size="sm" />}
                                            {t("Update Password")}
                                        </>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </Collapse>
                </div>

            </Form>
        </>
    )
}
export default EditPassword;