import i18next from "i18next";
import { LOADING, LOGIN_SUCCESS, LOGOUT, SET_ACCESS_TOKEN, SET_CURRENT_USER_STATUS, SET_CURRENT_WEBSITE, SET_CURRENT_WEBSITE_DATA, SET_CURRENT_WEBSITE_PLAN_DATA, SET_MESSAGE, SET_WEBSITE_LIST } from "../../Actions/Types";
import { DomainDetailsApi } from "../../Api/DomainApi/DomainDetailsApi";
import { UserLoginActionApi, getUserDetailsApi } from "../../Api/UserAction/LoginActionApi";
import { getViolationsCountApi, getViolationsListApi } from "../../Api/ViolationsApi";
import decodeBase64 from "../../Helpers/decodeBase64";
import encodeBase64 from "../../Helpers/encodeBase64";
import parseISODate from "../../Helpers/parseISODate";
import { getPlanListService } from "../PlanListService";

const UserLoginActionService = (
    username: string,
    password: string,
    focusEmailInput: Function, // function to shift focus on email input
    dispatch: Function, // redux dispatch function
    navigate: Function, // react router navigate function
    t: Function, // i18n t function 
    woocommerceToken?: string // woocommerce token
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Login API Call
    UserLoginActionApi(username, password).then((userResponse) => {

        // check if user data and token exists
        if (userResponse.Data && userResponse.token) {

            let loginWebsiteID: number;

            loginWebsiteID = (userResponse.Data.website_lists.length > 0 ? userResponse.Data.website_lists[0].id : 0);

            // function to get plan / packages
            getPlanListService(userResponse.token, 2, dispatch);

            //console.info("loginWebsiteID ==>", loginWebsiteID, (loginWebsiteID !== 0));

            let currentUserType: number;

            // check if its extension user
            if (userResponse.Data.is_extension_user === 1) {
                currentUserType = 5
            }
            // check if user department is 4 (partner user)
            else if (userResponse.Data.department === 4) {
                // checks if partner user has multiple domain plan
                if (userResponse.Data.is_multiple_domain === 1) {
                    currentUserType = 3
                } else {
                    currentUserType = 2
                }
            }
            // check if user department is 4 (partner user)
            else if (userResponse.Data.is_multiple_domain === 2) {
                currentUserType = 4
            } else {
                currentUserType = 1
            }

            // Set User Type
            dispatch({
                type: SET_CURRENT_USER_STATUS,
                payload: {
                    currentUserType: currentUserType, // 1 - normal user, 2 - normal partner, 3 - pre purchased, 4 - multi store, 5 - extension
                    isInitUser: loginWebsiteID === 0 ? true : false
                },
            });

            // Domain ID Check
            if (loginWebsiteID !== 0) {

                // API call to fetch Domain Details
                DomainDetailsApi(userResponse.token, loginWebsiteID).then((domainResponse) => {

                    // Check Domain Details Response is okay
                    if (domainResponse.status === 200) {

                        // api to get violations Count
                        getViolationsCountApi(userResponse.token, loginWebsiteID).then((scanDataRes) => {

                            // Check violation count Response is okay
                            if (scanDataRes.status === 200) {

                                // get violations error list Data
                                getViolationsListApi(userResponse.token, loginWebsiteID, i18next.language, 0).then((scanErrorViolationRes) => {

                                    // Check violation error list Response is okay
                                    if (scanErrorViolationRes.status === 200) {


                                        // get violations success list Data
                                        getViolationsListApi(userResponse.token, loginWebsiteID, i18next.language, 1).then((scanSuccessViolationRes) => {

                                            // Check violation success list Response is okay
                                            if (scanSuccessViolationRes.status === 200) {

                                                // Accessibility Score Percentage
                                                let accessibilityScore: number;
                                                let successAccessibilityChecks: number = scanDataRes.data[0].successAccessibilityChecks;
                                                let failingAccessibilityChecks: number = scanDataRes.data[0].failingAccessibilityChecks;

                                                // check if total violations are more than 0
                                                if ((successAccessibilityChecks + failingAccessibilityChecks) > 0) {
                                                    accessibilityScore = (parseFloat((((successAccessibilityChecks) / (successAccessibilityChecks + failingAccessibilityChecks)) * 100).toFixed(2)))
                                                } else {
                                                    accessibilityScore = 0;
                                                }

                                                // Violations Remediated Percentage
                                                let violationsRemediatedPercentage: number;
                                                let successViolations: number = scanSuccessViolationRes.total_counts;
                                                let failedViolations: number = scanErrorViolationRes.total_counts;

                                                // check if total violations are more than 0
                                                if ((successViolations + failedViolations) > 0) {
                                                    violationsRemediatedPercentage = (parseFloat((((successViolations) / (successViolations + failedViolations)) * 100).toFixed(2)))
                                                } else {
                                                    violationsRemediatedPercentage = 0;
                                                }

                                                // Image Alt Remediated Percentage
                                                let imageAltRemediatedPercentage: number;
                                                let successImageAlt: number = scanDataRes.data[0].total_update_alt_text;
                                                let failedImageAlt: number = scanDataRes.data[0].missing_alt_text;

                                                // check if total image alt violations are more than 0
                                                if ((successImageAlt + failedImageAlt) > 0) {
                                                    imageAltRemediatedPercentage = (parseFloat((((successImageAlt) / (successImageAlt + failedImageAlt)) * 100).toFixed(2)))
                                                } else {
                                                    imageAltRemediatedPercentage = 0;
                                                }

                                                // Set Current Website Data
                                                dispatch({
                                                    type: SET_CURRENT_WEBSITE_DATA,
                                                    payload: {
                                                        "accessibilityScore": accessibilityScore,
                                                        "violationsRemediatedPercentage": violationsRemediatedPercentage,
                                                        "successViolations": successViolations,
                                                        "failedViolations": failedViolations,
                                                        "imageAltRemediatedPercentage": imageAltRemediatedPercentage,
                                                        "successImageAlt": successImageAlt,
                                                        "failedImageAlt": failedImageAlt,
                                                        "successViolationsList": scanSuccessViolationRes.Data,
                                                        "failedViolationsList": scanErrorViolationRes.Data,
                                                    }
                                                });

                                                // Set Current Website Plan Details

                                                let isExpiredFlag: boolean = false;
                                                let isTrialFlag: boolean = false;
                                                let isInitFlag: boolean = false;
                                                let isFreeWidget: boolean = false;
                                                let isProPlanFlag: boolean = false;
                                                let isCanceledPlanFlag: boolean = false;
                                                let tempPlanName: string | null = null;
                                                let tempPlanExpiredData: string | null = null;

                                                // check if error violation data exists
                                                if (domainResponse.Data && domainResponse.Data.length > 0) {
                                                    if (domainResponse.Data[0].is_expired === 1) { // check if plan expired

                                                        // check if expired plan details are available
                                                        if (domainResponse.Data[0].expired_package_detail) {
                                                            isExpiredFlag = true;
                                                            isTrialFlag = false;
                                                            isInitFlag = false;
                                                            isFreeWidget = false;
                                                            isCanceledPlanFlag = false;
                                                            isProPlanFlag = false;
                                                            tempPlanName = domainResponse.Data[0].expired_package_detail[0].name;
                                                            tempPlanExpiredData = parseISODate(domainResponse.Data[0].expired_package_detail[0].end_date);
                                                        }

                                                    }
                                                    // check if current plan is trial plan
                                                    else if ((domainResponse.Data[0].is_expired === 3) && (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0)) {

                                                        isExpiredFlag = false;
                                                        isTrialFlag = true;
                                                        isCanceledPlanFlag = false;
                                                        isProPlanFlag = false;
                                                        isFreeWidget = false;
                                                        isInitFlag = (successViolations === 0 && failedViolations === 0) ? true : false;
                                                        tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                        tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                                    }
                                                    // check if current plan is cancelled
                                                    else if (domainResponse.Data[0].canceled_package_only && domainResponse.Data[0].canceled_package_only.length > 0) {

                                                        isExpiredFlag = false;
                                                        isTrialFlag = false;
                                                        isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                        isFreeWidget = false;
                                                        isProPlanFlag = false;
                                                        isCanceledPlanFlag = true;
                                                        tempPlanName = domainResponse.Data[0].canceled_package_only[0].name;
                                                        tempPlanExpiredData = parseISODate(domainResponse.Data[0].canceled_package_only[0].end_date);

                                                    } else {
                                                        // check if current plan data is available
                                                        if (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0) {

                                                            // check if current plan Free Widget
                                                            if (domainResponse.Data[0].current_package_only[0].is_free_widget === 1) {
                                                                isExpiredFlag = false;
                                                                isTrialFlag = false;
                                                                isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                                isFreeWidget = true;
                                                                isProPlanFlag = false;
                                                                isCanceledPlanFlag = false;
                                                                tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                                tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                                            } else {
                                                                // current plan is Pro Plan
                                                                isExpiredFlag = false;
                                                                isTrialFlag = false;
                                                                isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                                isFreeWidget = false;
                                                                isCanceledPlanFlag = false;
                                                                isProPlanFlag = true;
                                                                tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                                tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);
                                                            }

                                                        } else {
                                                            // No Plan details Found
                                                            isExpiredFlag = false;
                                                            isTrialFlag = false;
                                                            isInitFlag = false;
                                                            isFreeWidget = false;
                                                            isProPlanFlag = false;
                                                            isCanceledPlanFlag = false;
                                                            tempPlanName = null;
                                                            tempPlanExpiredData = null;
                                                        }

                                                    }
                                                }

                                                // dispatch current website plan details to redux store
                                                dispatch({
                                                    type: SET_CURRENT_WEBSITE_PLAN_DATA,
                                                    payload: {
                                                        isExpired: isExpiredFlag,
                                                        isTrial: isTrialFlag,
                                                        isInit: isInitFlag,
                                                        isFreeWidget: isFreeWidget,
                                                        isProPlan: isProPlanFlag,
                                                        isCanceledPlan: isCanceledPlanFlag,
                                                        planName: tempPlanName,
                                                        planExpiredData: tempPlanExpiredData
                                                    }
                                                });

                                                // Save Access Token
                                                dispatch({
                                                    type: SET_ACCESS_TOKEN,
                                                    payload: userResponse.token,
                                                });

                                                // Save User Data
                                                dispatch({
                                                    type: LOGIN_SUCCESS,
                                                    payload: { user: userResponse.Data },
                                                });

                                                // Save Website List
                                                dispatch({
                                                    type: SET_WEBSITE_LIST,
                                                    payload: userResponse.Data.website_lists
                                                })

                                                // Save Current Website Data
                                                // console.log(`test: domain res ${JSON.stringify(domainResponse, null, 2)}`);
                                                dispatch({
                                                    type: SET_CURRENT_WEBSITE,
                                                    payload: domainResponse.Data[0]
                                                });


                                                // check if woocommerce token exists, and domain data is available, redirect based on redirect ID 
                                                if (woocommerceToken && woocommerceToken !== '' && domainResponse.Data.length > 0) {
                                                    let tokenObj = decodeBase64(woocommerceToken).split("|");

                                                    let redirectID = tokenObj[0];
                                                    let planID = tokenObj[1];
                                                    let PlanSubID = tokenObj[2];
                                                    let planInterval = tokenObj[3];
                                                    let domainURL = domainResponse.Data[0].domain;
                                                    let userID = userResponse.Data.id;
                                                    let activeLanguageCode = 'en';



                                                    // check if redirect ID is ad1 then redirect to white label purchase
                                                    if (redirectID === 'ad1') {

                                                        // check if white label service is enabled
                                                        if (domainResponse.Data[0].white_label_enabled) {
                                                            navigate("/front/addon-services/white-label-service"); // navigate to white label
                                                        } else {
                                                            const urlString = `${planID}|${domainURL}|${planInterval}|${userID}|${activeLanguageCode}|1`;
                                                            const encodedString = encodeBase64(urlString);
                                                            navigate(`/front/package/${encodedString}`) // navigate to plan package
                                                        }
                                                    }
                                                    // check if redirect ID is ad2 then redirect to custom branding
                                                    else if (redirectID === 'ad2') {
                                                        navigate("/front/addon-services/custom-branding-service");
                                                    }
                                                    // check if redirect ID is ad3 then redirect to modify accessibility menu
                                                    else if (redirectID === 'ad3') {
                                                        // check if modify accessibility menu is enabled
                                                        if (domainResponse.Data[0].menu_features_enabled) {
                                                            navigate("/front/addon-services/modify-accessibility-menu");
                                                        } else {
                                                            const urlString = `${planID}|${domainURL}|${planInterval}|${userID}|${activeLanguageCode}|1`;
                                                            const encodedString = encodeBase64(urlString);
                                                            navigate(`/front/package/${encodedString}`)
                                                        }
                                                    }
                                                    // check if redirect ID is ad4 then redirect to live site translation
                                                    else if (redirectID === 'ad4') {
                                                        // check if live site translation is enabled
                                                        if (domainResponse.Data[0].live_site_translation_enabled) {
                                                            navigate("/front/addon-services/live-site-translation");
                                                        } else {
                                                            const urlString = `${planID}|${domainURL}|${planInterval}|${userID}|${activeLanguageCode}|2|${PlanSubID}`;
                                                            const encodedString = encodeBase64(urlString);
                                                            navigate(`/front/package/${encodedString}`)
                                                        }
                                                    }
                                                    // check if redirect ID is ad5 then redirect to PDF add on service
                                                    else if (redirectID === 'ad5') {
                                                        navigate("/front/addon-services/pdfs");
                                                    }
                                                    // check if redirect ID is ad6 then redirect to manual accessibility audit service
                                                    else if (redirectID === 'ad6') {
                                                        navigate("/front/addon-services/manual-audit-report");
                                                    }
                                                    // check if redirect ID is ad7 then redirect to VPAT audit report service
                                                    else if (redirectID === 'ad7') {
                                                        navigate("/front/addon-services/vpat-report");
                                                    }
                                                    // check if redirect ID is ad8 then redirect to Manual Accessibility Remediation service
                                                    else if (redirectID === 'ad8') {
                                                        navigate("/front/addon-services/manual-remediation");
                                                    }
                                                    // check if redirect ID is ad9 then redirect to design accessibility audit service
                                                    else if (redirectID === 'ad9') {
                                                        navigate("/front/addon-services/design-audit");
                                                    }
                                                    // check if redirect ID is ad10 then redirect to Native mobile app audit service
                                                    else if (redirectID === 'ad10') {
                                                        navigate("/front/addon-services/native-mobile-app-audit");
                                                    }
                                                    // check if redirect ID is ad11 then redirect to web app / SPA audit service
                                                    else if (redirectID === 'ad11') {
                                                        navigate("/front/addon-services/web-app-spa-audit");
                                                    } else {
                                                        navigate("/front");
                                                    }
                                                } else {
                                                    navigate('/front');
                                                }

                                                // remove Loading
                                                dispatch({
                                                    type: LOADING,
                                                    payload: false,
                                                });

                                                // dispatch login success message
                                                dispatch({
                                                    type: SET_MESSAGE,
                                                    payload: {
                                                        "toast_message": t('Login Success Msg'),
                                                        "toast_type": "success",
                                                        "onclose": null
                                                    }
                                                })
                                            }
                                        })

                                    }

                                })

                            }
                        })

                    } else {

                        // trigger logout if domain details are not found
                        dispatch({
                            type: LOGOUT,
                        });

                        // remove Loading
                        dispatch({
                            type: LOADING,
                            payload: false,
                        });

                        // navigate to login page with or without woocommerce token
                        if (woocommerceToken && woocommerceToken !== '') {
                            navigate(`/front/login?token=${woocommerceToken}`);
                        } else {
                            navigate("/front/login");
                        }

                        // dispatch login failed message
                        dispatch({
                            type: SET_MESSAGE,
                            payload: {
                                "toast_message": t('Login Fail Msg'),
                                "toast_type": "danger",
                                "onclose": null
                            }
                        })
                    }

                });

            } else {

                // navigation
                navigate(`/front/getting-started`);

                // Save Access Token
                dispatch({
                    type: SET_ACCESS_TOKEN,
                    payload: userResponse.token,
                });

                // Save User Data
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: userResponse.Data },
                });

                // Set Website List
                dispatch({
                    type: SET_WEBSITE_LIST,
                    payload: null
                });

                // Set Current Website Data
                dispatch({
                    type: SET_CURRENT_WEBSITE,
                    payload: null
                });

                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });


            }

        } else {

            // Logout
            dispatch({
                type: LOGOUT,
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // navigate
            if (woocommerceToken && woocommerceToken !== '') {
                navigate(`/front/login?token=${woocommerceToken}`);
            } else {
                navigate("/front/login");
            }

            focusEmailInput();
            // dispatch login failed message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Login Fail Msg'),
                    "toast_type": "danger",
                    "onclose": null
                }
            })

        }

    })
}


// service to get user details and handle it
const getUserDetailsService = async (
    token: string,
    dispatch: Function,
    isTokenSave?: boolean
): Promise<any> => {


    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });



    const userDetailsStatus = getUserDetailsApi(token).then(async (userDetails) => {

        // checks if user data is available
        if (userDetails.Data) {

            let currentUserType: number;

            // checks if its a extension user
            if (userDetails.Data.is_extension_user === 1) {
                currentUserType = 5
            }
            // checks if its a partner user
            else if (userDetails.Data.department === 4) {
                // checks if its a multi domain partner user
                if (userDetails.Data.is_multiple_domain === 1) {
                    currentUserType = 3
                } else {
                    currentUserType = 2
                }
            }
            // check if its a normal user with multiple domains
            else if (userDetails.Data.is_multiple_domain === 2) {
                currentUserType = 4
            } else {
                currentUserType = 1
            }

            // dispatch user type information
            dispatch({
                type: SET_CURRENT_USER_STATUS,
                payload: {
                    currentUserType: currentUserType,
                    isInitUser: userDetails.Data.website_lists.length === 0 ? true : false
                },
            });

            // Save User token
            if (isTokenSave) {
                dispatch({
                    type: SET_ACCESS_TOKEN,
                    payload: userDetails.token,
                });
            }

            // Save User Data
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: userDetails.Data },
            });

            // Set Website List
            dispatch({
                type: SET_WEBSITE_LIST,
                payload: userDetails.Data.website_lists
            })


            // Remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });


            return { status: 200 }

        } else {


            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });


            return { status: 400 }

        }
    });

    return userDetailsStatus;
}

export { UserLoginActionService, getUserDetailsService }
