import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";


import "../../Assets/scss/pages/settings.scss";
import NoRecordFound from "../../Components/NoRecordFound";
import { useEffect, useState } from "react";
import { UserCardPropTypes } from "../../PropTypes/CardListPropTypes";

// Card Logo
import Visa from '../../Assets/img/card/001-visa.svg';
import AmericanExpress from '../../Assets/img/card/002-american-express.svg';
import Discover from '../../Assets/img/card/007-discover.svg';
import JCB from '../../Assets/img/card/005-jcb.svg';
import MasterCard from '../../Assets/img/card/004-card.svg';
import DefaultCard from '../../Assets/img/card/003-credit-card.svg';
import expiredDate from "../../Helpers/expiredDate";
import { deleteCardService, getCardListService, makeDefaultCardService } from "../../Services/CardServices";
import { Elements } from "@stripe/react-stripe-js";
import CardPopup from "./CardPopup";
import { loadStripe } from "@stripe/stripe-js";
import DashboardBlankPagesTemplate from "../../Template/DashboardBlankPagesTemplate";
import AllUserOnlyTrialPlanInfo from "../../Components/ManagePlan/CurrentTrialPlanInfo";
import FreeWidgetPlanInfo from "../../Components/ManagePlan/FreeWidgetPlanInfo";
import AllUserOnlyActivePlanInfo from "../../Components/ManagePlan/CurrentActivePlanInfo";
import AllUserOnlyCanceledPlanInfo from "../../Components/ManagePlan/CanceledPlanInfo";
import { useNavigate } from "react-router-dom";

// loads stripe
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

// component to render settings page
const SettingsPage = () => {

    // Redux Data
    const { accessToken, currentWebsite, currentWebsitePlanStatus, currentUserStatus } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useState
    const [cardListData, setCardListData] = useState<UserCardPropTypes[]>()
    const [loadingCardList, setLoadingCardList] = useState(false);
    const [cardToken, setCardToken] = useState('');

    useEffect(() => {
        if (accessToken) {
            // service to get card list
            getCardListService(accessToken, setCardListData, setLoadingCardList);
        }
    }, [accessToken])

    useEffect(() => {        
        if(currentWebsite?.platform === 'wix' || currentWebsite?.platform === 'shopify' || currentWebsite?.platform === 'plesk'){
            navigate('/front');
        }
    }, [currentWebsite])

    // Show Card Popup
    const [showCardPopup, setShowCardPopup] = useState(false);
    const [showDeleteCardPopupLoading, setShowDeleteCardPopupLoading] = useState(false);
    const [showDeleteCardPopup, setDeleteCardPopup] = useState(false);
    const [deleteCardID, setDeleteCardID] = useState<number | null>();

    
    // function to close card popup
    const handleCloseCardPopup = () => {
        setShowCardPopup(false);
        setCardToken('');
    }
    // function to show card popup
    const handleShowCardPopup = () => {
        setShowCardPopup(true);
    }

    // function to close card delete popup
    const handleCloseDeleteCardPopup = () => {
        setDeleteCardPopup(false);
        setDeleteCardID(null)
    }

    // function to display delete card popup
    const handleShowDeleteCardPopup = (id: number) => {
        setDeleteCardPopup(true);
        id && setDeleteCardID(id);
    }

    /* const handleAddNewCard = () => {
        if (accessToken && cardToken !== '') {
            addNewCardService(accessToken, cardToken, setShowCardPopupLoading, setCardListData, setLoadingCardList, setShowCardPopup, dispatch);
        }
    } */

    // function to handle delete card
    const handleDeleteCard = () => {
        // check if access token and delete card ID are available
        if (accessToken && deleteCardID) {
            // service to perform delete card
            deleteCardService(accessToken, deleteCardID, setShowDeleteCardPopupLoading, setCardListData, setLoadingCardList, setDeleteCardPopup, dispatch, t);
        }
    }

    // function to display set default card popup
    const handleShowMakeDefaultCardPopup = (id: number) => {
        // check if access token and card ID and user confirmation are available,
        if (accessToken && id && window.confirm(t("Selected card will be primary, update your subscription and all future transactions will be done from this card").toString())) {
            // service to set default card
            makeDefaultCardService(accessToken, id, setCardListData, setLoadingCardList, setDeleteCardPopup);
        }
    }



    return (
        <>

            {currentUserStatus && (
                <>
                    {currentUserStatus.isInitUser ? (
                        <>
                            <DashboardBlankPagesTemplate>

                                <div className="aioa_dashboard-blank-pages-content-inner-wrapper">

                                    <DashboardPageTitle
                                        pageTitle={`${t("Settings")}`}
                                    />

                                    {currentWebsite && ((currentWebsite.platform === "wix" || currentWebsite.platform === 'shopify' || currentWebsite.platform === "Opencart-Pro" || currentWebsite.platform === "Magento-Pro")) ? (
                                        <>

                                        </>
                                    ) : (
                                        <>
                                            <div className="manage-cards">
                                                <Card>
                                                    <Card.Header>
                                                        <h2 className="mb-0 h5">{t('Card Details')}</h2>
                                                        <Button variant="primary" onClick={handleShowCardPopup} size="lg" className="aioa_dashboard-icon-btn">
                                                            <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">add_card</i></div>
                                                            <div className="aioa_dashboard-btn-text">{t('Add New Card')}</div>
                                                        </Button>
                                                    </Card.Header>

                                                    <Card.Body>
                                                        <div className="credit-card-list">

                                                            {cardListData ? (
                                                                <>
                                                                    {(cardListData.length > 0) ? (
                                                                        <>
                                                                            <Row xl={2}>
                                                                                {cardListData.map((cardItem) => {
                                                                                    return (
                                                                                        <Col key={cardItem.id}>
                                                                                            <div className={`credit-card-box ${cardItem.is_default === 1 ? `active` : ''}`} >
                                                                                                <div className={`credit-card-details`}>

                                                                                                    <div className="credit-card-img-box">
                                                                                                        {(cardItem.card_type).toLowerCase() === 'visa' ? (
                                                                                                            <>
                                                                                                                <img src={Visa} alt="Visa" />
                                                                                                            </>
                                                                                                        ) : (cardItem.card_type).toLowerCase() === 'mastercard' ? (
                                                                                                            <>
                                                                                                                <img src={MasterCard} alt="MasterCard" />
                                                                                                            </>
                                                                                                        ) : (cardItem.card_type).toLowerCase() === 'discover' ? (
                                                                                                            <>
                                                                                                                <img src={Discover} alt="Discover" />
                                                                                                            </>
                                                                                                        ) : (cardItem.card_type).toLowerCase() === 'american express' ? (
                                                                                                            <>
                                                                                                                <img src={AmericanExpress} alt="American Express" />
                                                                                                            </>
                                                                                                        ) : (cardItem.card_type).toLowerCase() === 'jcb' ? (
                                                                                                            <>
                                                                                                                <img src={JCB} alt="JCB" />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <img src={DefaultCard} alt="Card" />
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    <div className="credit-card-detail-box">
                                                                                                        <span><strong>{cardItem.card_type}</strong></span>
                                                                                                        <span><strong>XXXX-XXXX-XXXX-{cardItem.last_digits}</strong></span>
                                                                                                        <span>Expires {expiredDate(cardItem.exp_date)}</span>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {cardItem.is_default !== 1 ? (
                                                                                                    <>
                                                                                                        <div className="action-btns">
                                                                                                            <Button variant="link" onClick={() => handleShowMakeDefaultCardPopup(cardItem.id)}>{t('Make This Card Primary')}</Button>
                                                                                                            <Button variant="danger" aria-label={t("Delete Card").toString()} onClick={() => handleShowDeleteCardPopup(cardItem.id)}><i className={`material-symbols-outlined`} aria-hidden="true">delete</i></Button>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className="action-btns">
                                                                                                            <strong className="text-primary me-2">{t('Primary card')}</strong>
                                                                                                            <span className="btn btn-success mb-0 p-0"><i className={`material-symbols-outlined`} aria-hidden="true">check</i></span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}

                                                                                            </div>
                                                                                        </Col>
                                                                                    )
                                                                                })}
                                                                            </Row>

                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="w-100">
                                                                                <NoRecordFound imageType={`card`} joyful={false} noRecordText={`No Card Found.`} />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>



                                                                </>
                                                            )}

                                                        </div>
                                                    </Card.Body>

                                                </Card>

                                            </div>
                                        </>
                                    )}

                                </div>
                            </DashboardBlankPagesTemplate>
                        </>
                    ) : (
                        <>
                            <DashboardPagesTemplate>

                                <DashboardPageTitle
                                    pageTitle={`${t("Settings")}`}
                                    subPageTitle={`${t('page subtitle', { domainName: currentWebsite?.domain })}`}
                                />



                                {/* {currentWebsite && ((currentWebsite.platform === "wix" || currentWebsite.platform === 'shopify' || currentWebsite.platform === "Opencart-Pro" || currentWebsite.platform === "Magento-Pro")) ? (
                                    <>

                                    </>
                                ) : (
                                    <>
                                        {currentWebsite && currentWebsitePlanStatus && (
                                            <>

                                                <div className="aioa_dashboard-manage-plan aioa_dashboard-settings-screen">
                                                    {currentWebsitePlanStatus.isTrial ? (
                                                        <>
                                                            <AllUserOnlyTrialPlanInfo />
                                                        </>
                                                    ) : currentWebsitePlanStatus.isFreeWidget ? (
                                                        <>
                                                            <FreeWidgetPlanInfo />
                                                        </>
                                                    ) : currentWebsitePlanStatus.isCanceledPlan ? (
                                                        <>
                                                            <AllUserOnlyCanceledPlanInfo />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <AllUserOnlyActivePlanInfo />
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )} */}


                                {currentWebsite && ((currentWebsite.platform === "wix" || currentWebsite.platform === 'shopify' || currentWebsite.platform === "Opencart-Pro" || currentWebsite.platform === "Magento-Pro")) ? (
                                    <>

                                    </>
                                ) : (
                                    <>
                                        <div className="manage-cards">
                                            <Card>
                                                <Card.Header>
                                                    <h2 className="mb-0 h5">{t('Card Details')}</h2>
                                                    <Button variant="primary" onClick={handleShowCardPopup} size="lg" className="aioa_dashboard-icon-btn">
                                                        <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">add_card</i></div>
                                                        <div className="aioa_dashboard-btn-text">{t('Add New Card')}</div>
                                                    </Button>
                                                </Card.Header>

                                                <Card.Body>
                                                    <div className="credit-card-list">

                                                        {cardListData ? (
                                                            <>
                                                                {(cardListData.length > 0) ? (
                                                                    <>
                                                                        <Row xl={2}>
                                                                            {cardListData.map((cardItem) => {
                                                                                return (
                                                                                    <Col key={cardItem.id}>
                                                                                        <div className={`credit-card-box ${cardItem.is_default === 1 ? `active` : ''}`} >
                                                                                            <div className={`credit-card-details`}>

                                                                                                <div className="credit-card-img-box">
                                                                                                    {(cardItem.card_type).toLowerCase() === 'visa' ? (
                                                                                                        <>
                                                                                                            <img src={Visa} alt="Visa" />
                                                                                                        </>
                                                                                                    ) : (cardItem.card_type).toLowerCase() === 'mastercard' ? (
                                                                                                        <>
                                                                                                            <img src={MasterCard} alt="MasterCard" />
                                                                                                        </>
                                                                                                    ) : (cardItem.card_type).toLowerCase() === 'discover' ? (
                                                                                                        <>
                                                                                                            <img src={Discover} alt="Discover" />
                                                                                                        </>
                                                                                                    ) : (cardItem.card_type).toLowerCase() === 'american express' ? (
                                                                                                        <>
                                                                                                            <img src={AmericanExpress} alt="American Express" />
                                                                                                        </>
                                                                                                    ) : (cardItem.card_type).toLowerCase() === 'jcb' ? (
                                                                                                        <>
                                                                                                            <img src={JCB} alt="JCB" />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img src={DefaultCard} alt="Card" />
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>

                                                                                                <div className="credit-card-detail-box">
                                                                                                    <span><strong>{cardItem.card_type}</strong></span>
                                                                                                    <span><strong>XXXX-XXXX-XXXX-{cardItem.last_digits}</strong></span>
                                                                                                    <span>Expires {expiredDate(cardItem.exp_date)}</span>
                                                                                                </div>
                                                                                            </div>

                                                                                            {cardItem.is_default !== 1 ? (
                                                                                                <>
                                                                                                    <div className="action-btns">
                                                                                                        <Button variant="link" onClick={() => handleShowMakeDefaultCardPopup(cardItem.id)}>{t('Make This Card Primary')}</Button>
                                                                                                        <Button variant="danger" aria-label={t("Delete Card").toString()} onClick={() => handleShowDeleteCardPopup(cardItem.id)}><i className={`material-symbols-outlined`} aria-hidden="true">delete</i></Button>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div className="action-btns">
                                                                                                        <strong className="text-primary me-2">{t('Primary card')}</strong>
                                                                                                        <span className="btn btn-success mb-0 p-0"><i className={`material-symbols-outlined`} aria-hidden="true">check</i></span>
                                                                                                    </div>
                                                                                                </>
                                                                                            )}

                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            })}
                                                                        </Row>

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="w-100">
                                                                            <NoRecordFound imageType={`card`} joyful={false} noRecordText={`No Card Found.`} />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>



                                                            </>
                                                        )}

                                                    </div>
                                                </Card.Body>

                                            </Card>

                                        </div>
                                    </>
                                )}

                            </DashboardPagesTemplate>
                        </>
                    )}
                </>
            )
            }

            <Modal show={showCardPopup} onHide={handleCloseCardPopup} size="lg" fullscreen="lg-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Add New Card')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="card-input-group">

                            <Elements stripe={stripePromise} >
                                <CardPopup setCardListData={setCardListData} setLoadingCardList={setLoadingCardList} setShowCardPopup={setShowCardPopup} />
                            </Elements>

                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteCardPopup} onHide={handleCloseDeleteCardPopup} size="lg" fullscreen="lg-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Delete Card')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('Delete Card Popup Msg')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleDeleteCard}>
                        {t('Delete Button Label')}
                    </Button>
                    <Button variant="danger" onClick={handleCloseDeleteCardPopup}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SettingsPage;