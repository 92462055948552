import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Link } from "react-router-dom";

// component to render plan information on sidebar
const DomainPlanInfo = () => {

    // Redux Data
    const currentWebsitePlanStatus = useSelector((store: StoreProptypes) => store.currentWebsitePlanStatus);
    const user = useSelector((store: StoreProptypes) => store.user);
    
    // Functions
    const { t } = useTranslation();

    return (
        <>
            {user && user.department === 4 // check if partner user
            ? (
                <>
                    <Link to="/front" className="aioa_dashboard-plan-info partner-dashboard-btn">
                        <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">dashboard</i></div>
                        <div className="nav-link-text">{t("Partner Dashboard")}</div>
                    </Link>
                </>
            ) : (
                <>
                    {currentWebsitePlanStatus && (
                        <>
                            {currentWebsitePlanStatus.isExpired ? (
                                <>
                                    <div className="aioa_dashboard-plan-info border-danger">
                                        <strong className="text-danger">{t("Expired Plan Label")}</strong>: {currentWebsitePlanStatus.planName}
                                    </div>
                                </>
                            ) : (currentWebsitePlanStatus.isTrial) ? (
                                <>
                                    <div className="aioa_dashboard-plan-info border-warning">
                                        <strong className="text-warning">{t("Trial Plan Label")}</strong>: {currentWebsitePlanStatus.planName}
                                    </div>
                                </>
                            ) : (currentWebsitePlanStatus.isCanceledPlan) ? (
                                <>
                                    <div className="aioa_dashboard-plan-info border-dark">
                                        <strong className="text-dark">{t("Canceled Plan")}</strong>: {currentWebsitePlanStatus.planName}
                                    </div>
                                </>
                            ) : (currentWebsitePlanStatus.isInit || currentWebsitePlanStatus.isProPlan) ? (
                                <>
                                    <div className="aioa_dashboard-plan-info border-primary">
                                        <strong className="text-primary">{t("Your Plan Label")}</strong>: {currentWebsitePlanStatus.planName}
                                    </div>
                                </>
                            ) : (currentWebsitePlanStatus.isFreeWidget) ? (
                                <>
                                    <div className="aioa_dashboard-plan-info border-primary">
                                        <strong className="text-primary">{t("Your Plan Label")}</strong>: {currentWebsitePlanStatus.planName}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="aioa_dashboard-plan-info border-danger">
                                        <strong className="text-danger">{t("No Plan Found")}</strong>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default DomainPlanInfo;