import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import InVerified from '../../Assets/img/Inactive.png';
import PaymentButtonBlock from "../PaymentButton";
import StrikePrice from "../../Helpers/strikePrice";

interface PropTypes {
    item: websiteListPropType, // website object
    srno: number, // serial number
    handleShowAutoLoginModal: Function, // function to show auto login modal
    handleSetCurrentWebsite: Function, // function to set current website
}


// component to render website list table cell block
const CancelPlanWebsiteListTableCellBlock = (props: PropTypes) => {

    // PropTypes
    const { item, srno, handleShowAutoLoginModal, handleSetCurrentWebsite } = props;
    //console.log("hello from CancelPlanWebsiteListTableCellBlock")


    // Redux Data
    const { user, currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    return (
        <div className={`aioa_dashboard-table-tr ${user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 ? `primary-row` : ``}`}>

            {/* check if partner user and show price flag is set to 0 */}
            {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 && (
                <>
                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                            <div className="domain-name">
                                <div className="primary-domain">
                                    <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                    <div className="text-success">{t('Primary Domain')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Sr No")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip data-bs-html="true" id="button-tooltip">
                                    <span className="text-start">{t(`Not found any active plan.`)}</span>
                                </Tooltip>
                            }
                        >
                            <div className={`domain-verify-icon`}>
                                <img src={InVerified} alt='' />
                            </div>
                        </OverlayTrigger>
                        {item.domain}

                        {/* check if partner user and show price flag is set to 0 */}
                        {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 && (
                            <>
                                <div className="primary-domain">
                                    <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                    <div className="text-success">{t('Primary Domain')}</div>
                                </div>
                            </>
                        )}

                    </div>

                    {/* check if admin user */}
                    {(user && user.department === 1) && (
                        <>
                            <div className='email-filed'> <span>{t('Email')}</span> : {item.email}</div>
                        </>
                    )}


                    {/* check if admin user */}
                    {(user && user.department === 1) && (
                        <div className="link-btn-group">
                            <Button variant="link" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                        </div>
                    )}

                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">{item.domain}</div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Widget Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Widget Status").toString()}>
                    <div className="domain-name">{t("Not found any active plan.")}</div>
                </div>
            </div>

            {/* check if admin user */}
            {(user && user.department === 1) && (
                <>
                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Email")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Email").toString()}>
                            <div className='email-filed'>{item.email}</div>
                        </div>
                    </div>
                </>
            )}

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>
                    {item.canceled_package_only[0].name}
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong>{t('canceled label')}</strong></div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Price(USD)")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>

                    {/* check if partner user and show price flag is set to 0 */}
                    {user && user.department !== 1 && user.department !== 4 ? (
                        <>
                            {item.is_show_price === 0 ? (
                                <>{(item.used_canceled_discount === 0 && item.is_from_dashboard)
                                    ? <>
                                        {item.is_cancel_offer_accept === 1
                                            ? <>
                                                $<StrikePrice price={item.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={item.cancel_discount_percentage} />
                                            </>
                                            : <>
                                                $<StrikePrice price={item.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={item.reactivate_discount} />
                                            </>
                                        }
                                    </>
                                    : <>
                                        $<StrikePrice price={item.canceled_package_only[0].final_price} />
                                    </>
                                }

                                </>
                            ) : (
                                <>
                                    -
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            ${item.canceled_package_only[0].final_price}
                        </>
                    )}
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Canceled Date")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Canceled Date").toString()}>
                    <div className="aioa_dashboard-table-date-info">
                        <div className="aioa_dashboard-table-date-label d-none d-xl-block">{t('Canceled Date')}</div>
                        <div className="aioa_dashboard-table-date">{parseISODate(item.canceled_package_only[0].cancel_date)}</div>
                    </div>
                </div>
            </div>
            {/* check if admin user */}
            {user && user.department === 1 ? (
                <>
                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Total/Scan URLs")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total/Scan URLs").toString()}>-</div>
                    </div>

                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Used Ai Credits")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Used Ai Credits").toString()}>-</div>
                    </div>
                </>
            ) : (
                <>
                    <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Manage Plan")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>

                            {/* check if normal user with multiple domains, and show price flag is 0 */}
                            {user && user.is_multiple_domain === 2 ? (
                                <>
                                    {item.is_show_price === 0 ? (
                                        <>
                                            {/* check if offer on cancellation is not accepted */}
                                            {item.current_package_only[0] && item.current_package_only[0].is_cancel_offer_accept !== 1 ? (
                                                <>
                                                    <PaymentButtonBlock
                                                        activePlanDomain={item}
                                                        activePlanType={2}
                                                        buttonType={4}
                                                        activePlanInterval={item.canceled_package_only[0].subscr_interval}
                                                        activePlanPrice={item.canceled_package_only[0].final_price}
                                                        activeUserID={item.user_id}
                                                        isShowDiscount={(item.used_canceled_discount === 0 && item.is_from_dashboard) ? true : false}
                                                        activePlanID={item.canceled_package_only[0].package_id}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <PaymentButtonBlock
                                                        activePlanDomain={item}
                                                        activePlanType={2}
                                                        buttonType={4}
                                                        activePlanInterval={item.canceled_package_only[0].subscr_interval}
                                                        activePlanPrice={item.canceled_package_only[0].final_price}
                                                        activeUserID={item.user_id}
                                                        isShowDiscount={(item.used_canceled_discount === 0 && item.is_from_dashboard) ? true : false}
                                                        activePlanID={item.canceled_package_only[0].package_id}
                                                    />
                                                </>
                                            )}

                                        </>
                                    ) : (
                                        <>
                                            -
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {/* check if offer on cancellation is not accepted and show price flag is 0 */}
                                    {item.is_show_price === 0 && item.canceled_package_only[0].is_cancel_offer_accept !== 1 ? (
                                        <>
                                            <PaymentButtonBlock
                                                activePlanDomain={item}
                                                activePlanType={1}
                                                buttonType={4}
                                                activePlanInterval={item.canceled_package_only[0].subscr_interval}
                                                activePlanPrice={item.canceled_package_only[0].final_price}
                                                activeUserID={item.user_id}
                                                isShowDiscount={(item.used_canceled_discount === 0 && item.is_from_dashboard) ? true : false}
                                                activePlanID={item.canceled_package_only[0].package_id}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <PaymentButtonBlock
                                                buttonType={2}
                                                activePlanType={1}
                                                activePlanDomain={item}
                                                activePlanInterval={item.canceled_package_only[0].subscr_interval}
                                                activePlanPrice={item.canceled_package_only[0].final_price}
                                                websiteID={item.id}
                                                activeUserID={item.user_id}
                                                activePlanID={item.canceled_package_only[0].package_id}
                                                buttonVariant={`danger`}
                                                buttonLabel={`Reactivate Plan`}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* check if admin user */}
            {(user && user.department === 1) && (
                <>
                    <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap d-xl-none">
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                            <div className="link-btn-group">
                                <Button variant="primary" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </div>
    )
}

export default CancelPlanWebsiteListTableCellBlock;
