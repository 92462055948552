import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import IsVerified from '../../Assets/img/verified.png';
import InVerified from '../../Assets/img/Inactive.png';
import PaymentButtonBlock from "../PaymentButton";

interface PropTypes {
    item: websiteListPropType,
    srno: number,
    handleSetCurrentWebsite: Function
}

const ActiveWebsiteListTableCellBlock = (props: PropTypes) => {
    // PropTypes
    const { item, srno, handleSetCurrentWebsite } = props;


    // Redux Data
    const { user, currentWebsite, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    function handleShowAutoLoginModal(al_token: string, url: string): void {
        throw new Error("Function not implemented.");
    }

    function handleShowWidgetScriptCodeModal(widget_position: string, widget_color_code: string, api_key: string, url: string): void {
        throw new Error("Function not implemented.");
    }

    return (
       
        <div className={`aioa_dashboard-table-tr ${user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 ? `primary-row` : ``}`}>

        {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 && (
            <>
                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                        <div className="domain-name">
                            <div className="primary-domain">
                                <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                <div className="text-success">{t('Primary Domain')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}

        <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Sr No")}</div>
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
        </div>

        <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                <div className="domain-name">
                    {item.is_verified === 1 ? (
                        <>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip data-bs-html="true" id="button-tooltip">
                                        <span className="text-start">{t("Widget is active")}</span>
                                    </Tooltip>
                                }
                            >
                                <div className={`domain-verify-icon`}>
                                    <img src={IsVerified} alt='' />
                                </div>
                            </OverlayTrigger>
                        </>
                    ) : (
                        <>
                            {item.widget_last_use_date ? (
                                <>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip data-bs-html="true" id="button-tooltip">
                                                <span className="text-start">{t(`Widget was lastly used on ${parseISODate(item.widget_last_use_date)}`)}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <div className={`domain-verify-icon`}>
                                            <img src={InVerified} alt='' />
                                        </div>
                                    </OverlayTrigger>
                                </>
                            ) : (
                                <>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip data-bs-html="true" id="button-tooltip">
                                                <span className="text-start">{t("Widget activation is pending.")}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <div className={`domain-verify-icon`}>
                                            <img src={InVerified} alt='' />
                                        </div>
                                    </OverlayTrigger>
                                </>
                            )}
                        </>
                    )}
                    {item.domain}
                    {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 && (
                        <>
                            <div className="primary-domain">
                                <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                <div className="text-success">{t('Primary Domain')}</div>
                            </div>
                        </>
                    )}
                    
                </div>
                {(user && user.department === 1) && (
                    <>
                        <div className='email-filed'> <span>{t('Email')}</span> : {item.email}</div>
                    </>
                )}
                
            </div>
        </div>

    
        <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
            <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Plan")}</div>
                <div
                    className='aioa_dashboard-table-td-cell-value'
                    aria-label={t("Plan").toString()}
                >
                    
                    {item.current_package_only[0].name}

                    {(item?.current_package_only[0].subscr_interval === "M" ? (
                        <><br />({t("Monthly Plan Type")})</>
                    ) : (
                        <><br />({t("Annually Plan Type")})</>
                    ))}

                </div>

        </div>

        <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Widget Status")}</div>
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Widget Status").toString()}>
                <div className="domain-name">
                    {item.is_verified === 1 ? (
                        <>
                            <div>{t("Widget is active")}</div>
                        </>
                    ) : (
                        <>
                            {item.widget_last_use_date ? (
                                <>
                                    <div>{t(`Widget was lastly used on ${parseISODate(item.widget_last_use_date)}`)}</div>
                                </>
                            ) : (
                                <>
                                    <div>{t("Widget activation is pending.")}</div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>

        {(user && user.department === 1) && (
            <>
                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Email")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Email").toString()}>
                        <div className='email-filed'>{item.email}</div>
                    </div>
                </div>
            </>
        )}

        {/* <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Plan")}</div>
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>{item.current_package_only[0].name}</div>
        </div> */}

        <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong className="text-success">{t('Active')}</strong></div>
        </div>

        <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
            <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Price(USD)")}</div>
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>
                {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) ? (
                    <>
                        {item.is_show_price === 0 ? (
                            <>
                                ${item.current_package_only[0].final_price}
                            </>
                        ) : (
                            <>
                                -
                            </>
                        )}
                    </>
                ) : (
                    <>
                        ${item.current_package_only[0].final_price}
                    </>
                )}
            </div>
        </div>

        <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
            {item.current_package_only[0].is_free_widget ? <>{parseISODate(item.current_package_only[0].start_date)}</> :
                <>
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Renewal Date")}</div>

                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Renewal Date").toString()}>
                        <div className="aioa_dashboard-table-date-info active-plan-info">
                            <div className="aioa_dashboard-table-date-label d-none d-xl-block">{t('Renewal Date')}</div>
                            <div className="aioa_dashboard-table-date">
                                {parseISODate(item.current_package_only[0].end_date)}
                            </div>
                        </div>
                    </div>
                </>
            }

        </div>
        {user && user.department === 1 ? (
            <>
                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Total/Scan URLs")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total/Scan URLs").toString()}>{item.total_pages}/{item.total_scan_pages}</div>
                </div>

                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Used Ai Credits")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Used Ai Credits").toString()}>{item.AiCreditUsedCount}</div>
                </div>
            </>
        ) : (
            <>
                <div className="aioa_dashboard-table-td tcw-100 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Manage Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>
                    <Button variant="link" onClick={() => handleSetCurrentWebsite(item.id)}>
                        <i className="material-symbols-outlined" aria-hidden="true">settings</i>
                    </Button>
                </div>
            </div>
            </>
        )}

        <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap d-xl-none">
            <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                <div className="link-btn-group">
                    <Button variant="primary" onClick={() => handleSetCurrentWebsite(item.id)}>{t('view report btn label')}</Button>

                    {(item.is_verified !== 1 && (item.widget_last_use_date === '' || item.widget_last_use_date === null)) && (
                        <Button variant="primary" onClick={() => handleShowWidgetScriptCodeModal(item.widget_position, item.widget_color_code, item.current_package_only[0].api_key, item.url)}>
                            {t('Copy Code')}</Button>
                    )}
                    {(user && user.department === 1) && (
                        <Button variant="primary" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                    )}
                </div>
            </div>
        </div>

    </div>
    )
}

export default ActiveWebsiteListTableCellBlock;