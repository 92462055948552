import { useSelector } from "react-redux";
import aioaLogo from "../../Assets/img/all-in-one-accessibility-logo.svg";
import readWriteLogo from "../../Assets/img/ReadWriteMadeSimple-horizontal-logo.svg";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSearchParams } from "react-router-dom";


// component to render logo on dashboard
const DashboardLogo = () => {

    // Redux Data
    const user = useSelector((store: StoreProptypes) => store.user);

    //Functions
    const [searchParams] = useSearchParams();

    //Get app parameter
    const app = searchParams.get("app");

    return (
        <>
            <div className="aioa_dashboard-logo">
                {user ? <>
                    {user.is_extension_user // check if its a read write made simple extension user
                        ? <img src={readWriteLogo} alt="ReadWriteMadeSimple Logo" />
                        : <img src={aioaLogo} alt="All in One Accessibility Logo" />
                    }
                </> : <>
                    {(app && app === "readwritemadesimple") ? <img src={readWriteLogo} alt="ReadWriteMadeSimple Logo" />
                        : <img src={aioaLogo} alt="All in One Accessibility Logo" />}
                </>}


            </div>
        </>
    )
}

export default DashboardLogo;