import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { StoreProptypes } from '../PropTypes/StoreProptypes';

interface PropTypes {
  userid: number | null; // user id
  children: React.ReactElement<any, any> // children element
}

// component to protect routes if user not logged in
const ProtectedRoute = ({ userid, children }: PropTypes) => {

  const currentUserStatus = useSelector((store: StoreProptypes) => store.currentUserStatus);
  
  // check if user Id not exists
  if (!userid) {
    return <Navigate to={`/front/login${currentUserStatus.currentUserType === 5 ? '?app=readwritemadesimple' : ''}`} replace />;
  }

  return children;
};

export default ProtectedRoute